import get from 'lodash/get';
import ContentItemWithResolver from "models/ContentItemWithResolver";

export default class View extends ContentItemWithResolver {
	get content() {
		return get(this, '_content', []);
	}

	get SEO() {
		return get(this, '_seo[0]', null);
	}

	get slug() {
		return get(this, '_slug.taxonomyTerms[0].name', '');
	}
}
