import get from 'lodash/get';
import ContentItemWithResolver from "models/ContentItemWithResolver";
import { FieldModels } from 'kentico-cloud-delivery';
type AssetModel = FieldModels.AssetModel;

export default class PromoSection extends ContentItemWithResolver {
	get heading(): string {
		return get(this, '_heading.value', '');
	}

	get image(): AssetModel {
		return get(this, '_image.assets[0]', null);
	}

	get smallText(): string {
		return get(this, '_small_text.value', '');
	}

	get sidePromoText(): string {
		return get(this, '_side_promo_text.value', '');
	}

	get link() {
		return get(this, '_link[0]', []);
	}
}
