import React, { Component } from 'react';
import root from 'window-or-global';
import { inject, observer } from 'mobx-react';
import WindowStore from "stores/window.store";
import ShareButtons from "containers/BlogArticle/ShareButtons/ShareButtons";
import ArticleRichTextParser from 'components/RichTextParser/ArticleRichTextParser';
import { Bind } from 'lodash-decorators';
import styles from './ArticleContent.module.scss';
import { verticleFloatElementInBounds } from '../../../common/animations/methods';

export interface ArticleContentProps {
	windowStore: WindowStore;
	className?: string;
	data: any;
}

@inject('windowStore')
@observer
class ArticleContent extends Component<ArticleContentProps> {

	static defaultProps = {
		className: '',
	};

	shareButtonsTopFloatMargin = 145;
	shareButtonsBottomFloatMargin = 360;
	shareButtonsRef: any;
	contentRef: any;

	componentDidMount() {
		root.addEventListener('scroll', this.floatShareButtons);
		root.addEventListener('resize', this.floatShareButtons);
	}

	componentDidUpdate(prevProps) {
		const { data } = this.props;
		if (prevProps.data !== data) {
			if (this.shareButtonsRef && this.shareButtonsRef.base) {
				this.shareButtonsRef.base.style.transform = 'none';
			}
		}
	}

	componentWillUnmount() {
		root.removeEventListener('scroll', this.floatShareButtons);
		root.removeEventListener('resize', this.floatShareButtons);
	}

	@Bind
	floatShareButtons() {
		if (this.shareButtonsRef && this.shareButtonsRef.base && this.contentRef && this.contentRef.base) {
			const { windowStore } = this.props;
			const shareButtons = this.shareButtonsRef.base;
			const contentBounds = this.contentRef.base.getBoundingClientRect();
			if (windowStore.mediaSmallDesktop) {
				verticleFloatElementInBounds(shareButtons,
					contentBounds.y - this.shareButtonsTopFloatMargin,
					contentBounds.y + contentBounds.height - this.shareButtonsBottomFloatMargin);
			} else {
				shareButtons.style.transform = 'none';
			}
		}
	}

	render() {
		const {
			data,
		} = this.props;
		return (
			<div className={styles.Container}>
				<div className={styles.ShareButtonsContainer}>
					<ShareButtons
						ref={(ref) => this.shareButtonsRef = ref}
						className={styles.ShareButtons}
					/>
				</div>
				<ArticleRichTextParser
					ref={(ref) => this.contentRef = ref}
					className={styles.Content}
					kenticoContent={data.content}
				/>
			</div>
		);
	}
}

export default ArticleContent as any;
