import React, { Component } from "react";
import { InnerLink as InnerLinkModel } from "models";
import { observer, inject } from "mobx-react";
import NavigatorStore from "stores/navigator.store";
import { Bind } from "lodash-decorators";
import styles from "./InnerLink.module.scss";
import CoreStore from "stores/core.store";
import { computed } from "mobx";
import { withRouter } from "react-router";

export interface InnerLinkProps {
  className?: string;
  data: InnerLinkModel;
  coreStore: CoreStore;
  navigatorStore: NavigatorStore;
  history?;
}

@inject("navigatorStore", "coreStore")
@observer
class InnerLink extends Component<InnerLinkProps> {
  static defaultProps = {
    className: "",
  };

  @Bind
  onClick() {
    const {
      navigatorStore,
      data: { navigator, slug },
    } = this.props;
    navigatorStore.navigate(slug, navigator);
    this.props.history.push(slug);
  }

  @Bind
  @computed
  get active() {
    const {
      navigatorStore: { activeNavigatorId },
      data: { navigator, slug },
    } = this.props;
    if (navigator) {
      return navigator.id === activeNavigatorId;
    } else {
      const url = this.props.coreStore.history.location.pathname;
      return slug === url.slice(0, slug.length);
    }
  }

  render() {
    const { className, children } = this.props;

    if (!this.props.coreStore) {
      return null;
    }

    return (
      /* TODO: verify styles after change */

      <span
        className={`
					${className ? className : styles.Link}
					${this.active ? styles.Active : ""}
				`}
        onClick={this.onClick}
      >
        {children}
      </span>
    );
  }
}

export default withRouter(InnerLink) as any;
