import React, { FunctionComponent } from 'react';
import { Column as ColumnModel } from 'models';
import RichTextParser from 'components/RichTextParser/RichTextParser';
import { Markup } from 'components/Markup/Markup';
import styles from './Column.module.scss';

export interface ColumnProps {
	data: ColumnModel;
	className?: string;
	contentClassName?: string;
	children?: React.ReactNode;
}

const Column: FunctionComponent<ColumnProps> = ({ data, className, contentClassName }) => {
	const {
		headerText,
		headerImage,
		text,
	} = data;

	return (
		<div
			className={className || styles.Column}
		>
			{headerImage ? (
					<img
						className={styles.HeaderImage}
						src={headerImage.url}
						alt={headerText}
					/>
				) : (
					<Markup
						className={styles.Header}
						type="html"
						markup={headerText}
					/>
				)
			}
			<RichTextParser
				className={contentClassName || styles.Content}
				content={text}
			/>
		</div>
	);
};

Column.defaultProps = {
	className: '',
	contentClassName: '',
};

export default Column;
