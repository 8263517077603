import React, {  Component } from 'react';
import {
	FacebookShareButton,
	TwitterShareButton,
	LinkedinShareButton,
	FacebookIcon,
	TwitterIcon,
	LinkedinIcon,
} from 'react-share';
import root from 'window-or-global';
import { inject, observer } from 'mobx-react';
import WindowStore from "stores/window.store";
import styles from './ShareButtons.module.scss';

export interface ShareButtonsProps {
	windowStore: WindowStore;
	className?: string;
}

@inject('windowStore')
@observer
class ShareButtons extends Component<ShareButtonsProps> {

	static defaultProps = {
		className: '',
	}

	render() {
		const {
			className,
			windowStore,
		} = this.props;
		return (
			<div
				className={className}
			>
				<FacebookShareButton
					className={`${styles.ShareButton} ${styles.Facebook}`}
					url={root.location.href}
				>
					<FacebookIcon
						size={windowStore.mediaSmallDesktop ? 32 : 24}
					/>
					<span className={styles.ButtonText}>
						{'Share'}
					</span>
				</FacebookShareButton>
				<TwitterShareButton
					className={`${styles.ShareButton} ${styles.Twitter}`}
					url={root.location.href}
				>
					<TwitterIcon
						size={windowStore.mediaSmallDesktop ? 32 : 24}
					/>
					<span className={styles.ButtonText}>
						{'Tweet'}
					</span>
				</TwitterShareButton>
				<LinkedinShareButton
					className={`${styles.ShareButton} ${styles.Linkedin}`}
					url={root.location.href}
				>
					<LinkedinIcon
						size={windowStore.mediaSmallDesktop ? 32 : 24}
					/>
					<span className={styles.ButtonText}>
						{'Share'}
					</span>
				</LinkedinShareButton>
			</div>
		);
	}
}

export default ShareButtons as any;
