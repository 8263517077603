import React, { FunctionComponent } from 'react';
import styles from './SectionTitle.module.scss';

interface ISectionTitle {
	className?: string;
	title: string;
};

const SectionTitle: FunctionComponent<ISectionTitle> = ({ title, className }) => (
  <h2 className={`${styles.SectionTitle} ${className}`}>
    {title}
  </h2>
);

export default SectionTitle;
