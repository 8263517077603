import React, {  FunctionComponent } from 'react';
import styles from './Tag.module.scss';

export interface TagProps {
	className?: string;
}

export const Tag: FunctionComponent<TagProps> = ({ className, children }) => {
	return (
		<span className={`${className} ${styles.Tag}`}>
			{children}
		</span>
	);
};
