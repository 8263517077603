import { ContentItem } from 'kentico-cloud-delivery';
import get from 'lodash/get';
import React from 'react';
import RichTextComponentResolver from '../components/RichTextParser/RichTextComponentResolver';

export default class ContentItemWithResolver extends ContentItem {
	constructor() {
		super({
			propertyResolver: (fieldName) => `_${fieldName}`,
			richTextResolver: (item) => {

				// TODO: Verify if it works
				return (<RichTextComponentResolver item={item} />) as any;
			},
		});
	}

	get type() {
		return get(this, 'system.type', '');
	}

	get id() {
		return get(this, 'system.id', '');
	}
}
