import React, {  FunctionComponent } from 'react';
import { PromoSection as PromoSectionModel } from 'models';
import LinkParser from 'components/LinkParser/LinkParser';
import Layout from 'components/Layout/Layout';
import Asset from 'components/Asset/Asset';
import { isIE } from 'react-device-detect';
import styles from './PromoSection.module.scss';

export interface PromoSectionProps {
	className?: string;
	data: PromoSectionModel;
}

const PromoSection: FunctionComponent<PromoSectionProps> = ({ className, data }) => {
	const {
		heading,
		image,
		smallText,
		sidePromoText,
		link,
	} = data;

	return (
		<Layout className={className}>
			<LinkParser data={link}>
				<section className={styles.MainContainer}>
					<h3 className={styles.MainHeading}>
						{heading}
					</h3>
					<h4 className={styles.SmallText}>
						<span className={styles.GreyLine} />
						{smallText}
					</h4>
					{image && (
						 <Asset
						 	data={image}
							className={`${styles.ImageContainer} ${isIE ? styles.ImageContainerIE : ''}`}
							classNameIE={styles.ImageContainerIE}
							assetClassName={styles.Image}
						 />
					)}
					<h4 className={styles.SidePromoText}>
						{sidePromoText}
					</h4>
				</section>
			</LinkParser>
		</Layout>
	);
};

PromoSection.defaultProps = {
	className: '',
};

export default PromoSection;
