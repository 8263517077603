import {
	observable,
	action,
} from 'mobx';
import { throttle } from 'lodash';
import root from 'window-or-global';
import {
	ImageUrlBuilder,
	ImageCompressionEnum,
	ImageFormatEnum,
} from 'kentico-cloud-delivery';
import BREAK_POINTS from 'common/responsive/breakpoints';
import { DIRECTION } from 'common/consts/common';
import supportsWebP from 'supports-webp';
import { isMobile } from 'react-device-detect';

class WindowStore {
	@observable isMobile = isMobile;

	@observable mediaMobile = false;

	@observable mediaTablet = false;

	@observable mediaSmallDesktop = false;

	@observable mediaRegularDesktop = false;

	@observable mediaLargeDesktop = false;

	@observable mediaExtraLargeDesktop = false;

	@observable screenWidth = root.innerWidth;

	@observable screenHeight = root.innerHeight;

	@observable scrollY = root.scrollY || root.pageYOffset;

	@observable scrollDirection = DIRECTION.TOP;

	constructor() {
		this.onResize();
		if (root.addEventListener) {
			root.addEventListener('resize', throttle(this.onResize));
			root.addEventListener('scroll', throttle(this.onScroll));
		}
	}

	@action.bound
	onResize() {
		this.screenHeight = root.innerHeight;
		const innerWidth = root.innerWidth;

		this.screenWidth = innerWidth;
		this.mediaMobile = innerWidth >= BREAK_POINTS.MOBILE;
		this.mediaTablet = innerWidth >= BREAK_POINTS.TABLET;
		this.mediaSmallDesktop = innerWidth >= BREAK_POINTS.SMALL_DESKTOP;
		this.mediaRegularDesktop = innerWidth >= BREAK_POINTS.REGULAR_DESKTOP;
		this.mediaLargeDesktop = innerWidth >= BREAK_POINTS.LARGE_DESKTOP;
		this.mediaExtraLargeDesktop = innerWidth >= BREAK_POINTS.EXTRA_LARGE_DESKTOP;
	}

	@action.bound
	onScroll() {
		if (this.scrollY > (root.scrollY || root.pageYOffset)) {
			this.scrollDirection = DIRECTION.TOP;
		} else if (this.scrollY < root.scrollY) {
			this.scrollDirection = DIRECTION.BOTTOM;
		}
		this.scrollY = root.scrollY || root.pageYOffset;
	}

	static buildPhotoURL(photoWidth: number, url: string) {
		const urlBuilder = new ImageUrlBuilder(url);
		urlBuilder.withWidth(photoWidth);
		if (supportsWebP) {
			urlBuilder
				.withFormat(ImageFormatEnum.Webp)
				.withCompression(ImageCompressionEnum.Lossy);
		}
		return urlBuilder.getUrl();
	}
}

export default WindowStore;
