import React, {  Component } from 'react';
import { ThreeColumnsWithHeaderSection as ThreeColumnsWithHeaderSectionModel } from 'models';
import Layout from 'components/Layout/Layout';
import Column from 'components/Column/Column';
import { Header } from 'components/Header/Header';
import Background, {setColor} from 'components/Background/Background';
import Asset from 'components/Asset/Asset';
import HorizontalLine from 'components/HorizontalLine/HorizontalLine';
import DownButton from 'components/Buttons/Arrow/DownButton';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Bind } from 'lodash-decorators';
import styles from './ThreeColumnsWithHeaderSection.module.scss';

export interface IThreeColumnsWithHeaderSection {
	data: ThreeColumnsWithHeaderSectionModel;
}

@observer
class ThreeTextColumns extends Component<IThreeColumnsWithHeaderSection> {

	@observable visibleColumnIndex = 0;

	columnRefs: any = [];

	@Bind
	onArrowButtonClick(columnIndex) {
		this.visibleColumnIndex = this.visibleColumnIndex === columnIndex ? 0 : columnIndex;

		this.columnRefs.forEach((ref, index) => {
			if (!!index) {
				ref.children[1].style.height = '85px';
			}
		});

		const column = this.columnRefs[this.visibleColumnIndex].children[1];
		const columnBody = column.children[1];
		column.style.height =	(columnBody.getBoundingClientRect().height + 100) + 'px';
	}

	render() {
		const {
			data: {
				headerText,
				headingText,
				columns,
				backgroundAsset,
				textColor,
				backgroundColor,
			},
		} = this.props;

		return (
			<Background
				className={styles.MainContainer}
				data={{backgroundColor, textColor}}
			>
				{backgroundAsset &&
				<Asset
					data={backgroundAsset}
					className={styles.AssetContainer}
					assetClassName={styles.BackgroundAsset}
				/>
				}
				<Layout className={styles.Container}>
					{headingText && (
						<Header className={styles.Header} text={headerText}/>
					)}
					<h3 className={styles.MainHeading}>{headingText}</h3>
					{columns.map((column, index) => (
						<div
							ref={(ref) => { this.columnRefs[index] = ref; }}
							key={column.system.id}
						>
							{!!index && <DownButton
								onClick={() => this.onArrowButtonClick(index)}
								style={{borderColor: setColor(textColor)}}
								className={`
									${this.visibleColumnIndex === index && styles.CarouselArrowOpened}
									${styles.CarouselArrow}
								`}
							/>}
							<Column
								className={`
									${!index ? styles.FirstColumn : styles.Column}
									${!!index && this.visibleColumnIndex !== index && styles.ColumnHidden}
								`}
								contentClassName={styles.ColumnContent}
								data={column}
							/>
							<HorizontalLine className={styles.HorizontalLine}/>
						</div>
					))}
				</Layout>
			</Background>
		);
	}
}

export default ThreeTextColumns;
