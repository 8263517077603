import React, {  Component } from 'react';
import get from 'lodash/get';
import Background from 'components/Background/Background';
import Layout from 'components/Layout/Layout';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import {
	SliderSection as SliderSectionModel,
} from 'models';
import Slider from 'components/Slider/Slider';
import styles from './TestimonialsSection.module.scss';

export interface ISliderSectionProps {
	data: SliderSectionModel;
}

export default class SliderSection extends Component<ISliderSectionProps> {

  componentDidMount = () => {
    this.props.data.testimonialsItems.forEach(testimonialItem => {
      const image = new Image();
      image.src = get(testimonialItem, 'logo.value[0].url');
    });
  }
	
	render() {
		const {
			data: {
        headerText,
        testimonialsItems,
			},
    } = this.props;

		return (
      <Background
        data={{ backgroundColor: 'white', textColor: '' }}
        className={styles.Container}>
        <Layout className={styles.Layout}>
          <SectionTitle title={headerText} />
          <Slider
            slides={testimonialsItems}
            buttonsWrapperClassName={styles.Buttons}
            isQuotation
          >
          </Slider>
        </Layout>
			</Background>
		);
	}
}
