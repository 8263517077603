import get from 'lodash/get';
import ContentItemWithColorPickers from "models/ContentItemWithColorPickers";
import { HeroHeadingText } from 'models';

export default class HeroSection extends ContentItemWithColorPickers {
	get headingLogo() {
		return get(this, '_heading_logo.value[0].url', '');
	}

	get headingText() {
		return get(this, '_heading_text.value', '');
	}

	get headingChangingTexts(): HeroHeadingText[] {
		return get(this, '_heading_changing_texts', []);
	}

	get description() {
		return get(this, '_description.value', '');
	}

	get links() {
		return get(this, '_links', []);
	}

	get backgroundAsset() {
		return get(this, '_background_asset.assets[0]', null);
	}

	get mobileBackgroundAsset() {
		return get(this, '_mobile_background_asset.assets[0]', null);
	}
}
