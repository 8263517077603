/* eslint-disable react/prop-types */
import React, { Component } from "react";
import get from "lodash/get";
import { Bind } from "lodash-decorators";
import { ContactFormSection as ContactFormModel } from "models";
import { FORM_STATE, SUCCESS_MESSAGE_TIMEOUT } from "common/consts/form";
import Button from "components/Buttons/Button/Button";
import Layout from "components/Layout/Layout";
import { observable } from "mobx";
import { observer, inject } from "mobx-react";
import MailingService from "services/mailing.service";
import styles from "./ContactFormSection.module.scss";
import Input from "components/Input/Input";
import DropDown from "components/DropDown/DropDown";
import CheckBox from "components/CheckBox/CheckBox";
// TO DO: uncomment when voice messages fixed
// import VoiceRecorder from 'components/VoiceRecorder/VoiceRecorder';
import RichTextParser from "components/RichTextParser/RichTextParser";
import { escapeHtmlWithoutBr } from "common/methods/string";
import Background from "components/Background/Background";
import CoreStore from "../../stores/core.store";
import Anchor from "../../common/consts/anchor";
import { withRouter } from "react-router";

export interface ContactFormSectionProps {
  data: ContactFormModel;
  coreStore: CoreStore;
}

@inject("coreStore")
@observer
class ContactFormSection extends Component<ContactFormSectionProps> {
  name = observable.box("");

  email = observable.box("");

  company = observable.box("");

  phone = observable.box("");

  message = observable.box();

  selectedOption = observable.box("");

  dropDownError = observable.box(false);

  // TO DO: uncomment when voice messages fixed
  // voiceMessage = observable.box();

  processingDataCheckBox = observable.box(false);

  commercialInfoCheckBox = observable.box(false);

  directMarketingCheckBox = observable.box(false);

  @observable formState = FORM_STATE.DEFAULT;

  @observable resetValue = false;

  @observable formDirty = false;

  setInitialState() {
    this.formState = FORM_STATE.DEFAULT;
  }

  componentDidMount() {
    this.checkForHash();
  }

  componentDidUpdate(prevProps) {
    this.checkForHash();
  }

  checkForHash() {
    const {
      data: { selectOption1 },
    } = this.props;
    if (window.location.hash && window.location.hash === Anchor.NEWSLETTER) {
      this.selectedOption.set(selectOption1);
    }
  }

  resetForm() {
    this.name.set("");
    this.email.set("");
    this.company.set("");
    this.phone.set("");
    this.message.set("");
    // TO DO: uncomment when voice messages fixed
    // this.voiceMessage.set(null);
    this.processingDataCheckBox.set(false);
    this.commercialInfoCheckBox.set(false);
    this.directMarketingCheckBox.set(false);
  }

  @Bind
  onSelect(option) {
    const {
      data: { selectOption2 },
    } = this.props;

    if (
      option === selectOption2 &&
      window.location.hash === Anchor.NEWSLETTER
    ) {
      window.history.replaceState(null, "", " ");
    }

    this.dropDownError.set(false);
    this.selectedOption.set(option);
  }

  @Bind
  async submitForm(submitEvent) {
    submitEvent.preventDefault();
    const {
      coreStore: { referrerURL, currentURL, previousURL },
      data: { selectOption1 },
    } = this.props;

    if (!this.selectedOption.get().length) {
      this.formState = FORM_STATE.ERROR;
      this.dropDownError.set(true);
      return;
    }

    if (this.formState === FORM_STATE.ERROR) {
      this.formState = FORM_STATE.DEFAULT;
      return;
    }

    if (this.formState !== FORM_STATE.DEFAULT) {
      return;
    }

    this.formState = FORM_STATE.LOADING;
    const checkReferrer =
      previousURL === null ? "no previous URL" : previousURL;

    const contactReasonText = this.selectedOption;
    const contactReasonValue =
      this.selectedOption.get() === selectOption1
        ? "personal-investment"
        : "project-investment";
    try {
      await MailingService.sendContactForm({
        name: this.name.get(),
        company: this.company.get(),
        email: this.email.get(),
        phone: this.phone.get(),
        message: this.message.get(),
        contactReasonText,
        contactReasonValue,
        commercialInfoCheckBox: this.commercialInfoCheckBox.get()
          ? get(this.props, "data.commercialInfoCheckBoxText")
          : false,
        directMarketingCheckBox: this.directMarketingCheckBox.get()
          ? get(this.props, "data.directMarketingCheckBox")
          : false,
        url: currentURL,
        source: referrerURL,
        referrer: checkReferrer,
        // TO DO: uncomment when voice messages fixed
        // voiceMessage: this.voiceMessage.get(),
      });

      this.formState = FORM_STATE.SUCCESS;

      setTimeout(() => {
        this.setInitialState();
      }, SUCCESS_MESSAGE_TIMEOUT);

      this.resetValue = false;
      this.resetForm();
    } catch (e) {
      this.formState = FORM_STATE.ERROR;
    }
  }
  constructor(props) {
    super(props);
    this.resetForm();
    this.setInitialState();
  }

  render() {
    const {
      data: {
        headingText,
        contactDetailsHeading,
        contactDetails,
        emailLabel,
        nameLabel,
        companyLabel,
        phoneLabel,
        messageLabel,
        commercialInfoCheckBoxText,
        directMarketingCheckBoxText,
        introductionToThePrivacyPolicy,
        sendButtonText,
        sendLoadingText,
        sendSuccessText,
        sendErrorText,
        textColor,
        backgroundColor,
        selectDefault,
        selectOption1,
        selectOption2,
        // TODO: uncomment that when voice recorder return
        // voiceRecorder,
        agreementText,
      },
    } = this.props;

    let buttonText = sendButtonText;

    switch (this.formState) {
      case FORM_STATE.ERROR:
        buttonText = sendErrorText;
        break;
      case FORM_STATE.LOADING:
        buttonText = sendLoadingText;
        break;
      case FORM_STATE.SUCCESS:
        buttonText = sendSuccessText;
        break;
      default:
        buttonText = sendButtonText;
        break;
    }

    const selectValueList = [
      { value: selectOption1, representation: selectOption1 },
      { value: selectOption2, representation: selectOption2 },
    ];

    return (
      <Background data={{ textColor, backgroundColor }}>
        <Layout className={styles.LayoutStyle}>
          <div className={styles.Content}>
            <div className={styles.ContactInfo}>
              <h2 className={styles.Heading}>{headingText}</h2>
              <div className={styles.InfoContainer}>
                <h2
                  className={styles.InfoHeading}
                  dangerouslySetInnerHTML={{
                    __html: escapeHtmlWithoutBr(contactDetailsHeading),
                  }}
                />
                <RichTextParser kenticoContent={contactDetails} />
              </div>
            </div>
            <form
              onInput={() => {
                this.formDirty = true;
              }}
              onSubmit={(e) => this.submitForm(e)}
              className={styles.Form}
            >
              <DropDown
                isError={this.dropDownError.get()}
                mobileLayoutChange={false}
                className={styles.DropDown}
                value={this.selectedOption.get()}
                valueList={selectValueList}
                onSelect={this.onSelect}
                defualtValue={selectDefault}
              />
              <Input
                label={nameLabel}
                valueObservableBox={this.name}
                className={styles.Input}
                required
              />
              <Input
                label={companyLabel}
                valueObservableBox={this.company}
                className={styles.Input}
                required
              />
              <Input
                label={phoneLabel}
                valueObservableBox={this.phone}
                pattern="^[0-9-+\s()]*$"
                className={styles.Input}
              />
              <Input
                label={emailLabel}
                valueObservableBox={this.email}
                type="email"
                className={styles.Input}
                required
              />
              <Input
                label={messageLabel}
                valueObservableBox={this.message}
                type="textarea"
                className={styles.Input}
                //   required={!this.voiceMessage.get()}
              />
              {/* TODO: return that when file works */}
              {/* <div className={styles.ActionsButtonWrapper}>
                  <VoiceRecorder
                    valueObservableBox={this.voiceMessage}
                    className={styles.Input}
                    data={voiceRecorder}
                  >
                   
                  </VoiceRecorder>
                </div> */}
              <Button type="submit" className={styles.Button}>
                {buttonText}
              </Button>
              <div
                className={
                  this.formDirty
                    ? styles.CheckBoxPanelVisible
                    : styles.CheckBoxPanelHidden
                }
              >
                {introductionToThePrivacyPolicy && (
                  <RichTextParser
                    content={introductionToThePrivacyPolicy}
                    className={styles.Consent}
                  />
                )}
                {commercialInfoCheckBoxText && (
                  <CheckBox
                    required
                    valueObservableBox={this.commercialInfoCheckBox}
                    safeHtmlLabel={commercialInfoCheckBoxText}
                    className={styles.Input}
                  />
                )}
                {directMarketingCheckBoxText &&
                  this.phone &&
                  this.phone.get() && (
                    <CheckBox
                      required
                      valueObservableBox={this.directMarketingCheckBox}
                      safeHtmlLabel={directMarketingCheckBoxText}
                      className={styles.Input}
                    />
                  )}
                {agreementText && (
                  <RichTextParser
                    content={agreementText}
                    className={styles.Consent}
                  />
                )}
              </div>
            </form>
          </div>
        </Layout>
      </Background>
    );
  }
}

export default withRouter(ContactFormSection) as any;
