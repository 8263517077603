import React, {  FunctionComponent } from 'react';
import fontawesome from '@fortawesome/fontawesome';

export interface IconProps {
	icon: any;
	className?: string;
}

const Icon: FunctionComponent<IconProps> = ({ icon, className }) => {
	return (
		<i
			dangerouslySetInnerHTML={{ __html: fontawesome.icon(icon).html.join() }}
			className={className}
		/>
	);
};

export default Icon;
