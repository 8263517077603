export const AUTOPLAY_SPEED = 8000;
export const ICON_SLIDER_AUTOPLAYSPPED = 10000;

const SLIDER_SETTINGS = (autoplay: boolean, speed: number = AUTOPLAY_SPEED) => ({
  infinite: true,
  speed: 1000,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay,
  autoplaySpeed: speed,
});

export const AUTOPLAY = 8000;
export const ANIMATION_TIME = '8s';

export const MULIPLE_COLUMN_SLIDER_SETTINGS = (autoplay: boolean, speed: number = AUTOPLAY_SPEED) => ({
  infinity: false,
  arrows: false,
  slidesToShow: 6,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: speed,
  responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 3,
        infinity: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        infinity: true,
      },
    },
  ],
});

export default SLIDER_SETTINGS;
