import React, {  FunctionComponent } from 'react';
import { BlockQuote as BlockQuoteModel } from 'models';
import styles from './BlockQuote.module.scss';

export interface BlockQuoteProps {
	className?: string;
	data: BlockQuoteModel;
}

const BlockQuote: FunctionComponent<BlockQuoteProps> = ({ className, data }) => {
	return (
		<blockquote
			className={`${className} ${styles.Container}`}
		>
			<p>
				{data.text}
			</p>
			{data.author && (
				<cite>
					{
						data.citeLink
							? (
								<a
								href={data.citeLink}
								rel="noopener noreferrer"
								target="_blank"
							>
								{data.author}
							</a>
							) : data.author
					}
				</cite>
			)}
		</blockquote >
	);
};

BlockQuote.defaultProps = {
	className: '',
};

export default BlockQuote;
