import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';
import { Slide } from './index';
import { FieldModels } from 'kentico-cloud-delivery/_es2015';
type AssetModel = FieldModels.AssetModel;

export default class BlogAuthor extends ContentItemWithResolver {
	get authorData(): Slide {
		return get(this, '_author_data[0]', null);
	}

	get smallPicture(): AssetModel {
		return get(this, '_small_picture.assets[0]', null);
	}

	get link() {
		return get(this, '_link[0]', null);
	}
}
