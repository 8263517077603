const SLUG = {
	BLOG: {
		pl: 'blog',
		en: 'blog',
	},
    PRIVACY_POLICY: {
		pl: 'polityka-prywatnosci',
		en: 'privacy-policy',
	},
	TERMS_OF_USE: {
		pl: 'regulamin',
		en: 'terms-of-use',
	},
	CASE_STUDIES: {
		pl: 'case-studies',
		en: 'case-studies'
	}
};

export default SLUG;
