import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Layout from "components/Layout/Layout";
import SectionsFilter from 'blocks/CaseStudiesListing/SectionsFilter/SectionsFilter';
import Pagination from "blocks/CaseStudiesListing/Pagination/Pagination";
import PromoSection from "blocks/PromoSection/PromoSection";
import { PromoSection as PromoSectionModel } from 'models';
import CoreStore from "stores/core.store";
import { show } from "common/animations/animejs";
import styles from './CaseStudiesListing.module.scss';
import CaseStudiesStore from "../../stores/caseStudies.store";
import CaseStudyBox from "blocks/CaseStudyBox/CaseStudyBox";

export interface CaseStudiesListingProps {
	caseStudiesStore: CaseStudiesStore;
	coreStore: CoreStore;
	data: {
		caseStudyHeader: string;
		caseStudySubHeader: string;
		promoSection: PromoSectionModel;
		filterSelectAllText: string;
		filterSelectAllLawText: string;
	};
	history?;
}

@inject('coreStore', 'caseStudiesStore')
@observer
class CaseStudiesListing extends Component<CaseStudiesListingProps> {

	listingContentRef: any;

	constructor(props) {
		super(props);
		props.caseStudiesStore.getArticleList();
	}

	componentDidUpdate(prevProps) {
		show(this.listingContentRef, 300);
	}

	render() {
		const {
			caseStudiesStore: {
				pagedArticleList,
				caseStudiesListLoaded,
			},
			data: {
				caseStudyHeader,
				caseStudySubHeader,
				promoSection,
				filterSelectAllText,
				filterSelectAllLawText,
			},
		} = this.props;

		const articlesInRow = 1;

		return (
			<Layout>
				<header className={styles.caseStudyHeader}>
					{caseStudyHeader}
					<div className={styles.caseStudySubHeader}>
						{caseStudySubHeader}
					</div>
				</header>
				<div className={styles.Filters}>
					<SectionsFilter
						noValueText={filterSelectAllText}
						noValueLawText={filterSelectAllLawText}
					/>
				</div>
				<div
					ref={(ref) => this.listingContentRef = ref}
				>
					<section className={styles.ArticleBoxesContainer}>
						{caseStudiesListLoaded && pagedArticleList.slice(0, articlesInRow).map((articleData) => (
							<CaseStudyBox
								className={styles.ArticleBox}
								data={articleData}
								key={articleData.id}
							/>
						))}
					</section>

					<section className={styles.ArticleBoxesContainer}>
						{caseStudiesListLoaded && pagedArticleList.slice(articlesInRow, articlesInRow * 2).map((articleData) => (
							<CaseStudyBox
								className={styles.ArticleBox}
								data={articleData}
								key={articleData.id}
							/>
						))}
					</section>

					{promoSection && <PromoSection className={styles.InnerSection} data={promoSection} />}

					<section className={styles.ArticleBoxesContainer}>
						{caseStudiesListLoaded && pagedArticleList.slice(articlesInRow * 2, articlesInRow * 3).map((articleData) => (
							<CaseStudyBox
								className={styles.ArticleBox}
								data={articleData}
								key={articleData.id}
							/>
						))}
					</section>
				</div>
				<Pagination />
			</Layout>
		);
	}
}

export default CaseStudiesListing as any;
