export const removeSpaces = text => text.replace(/\s+/g, '');
export const slashesToDashes = text => text.replace(/[-/]+/g, '_');
export const underscoresToDashes = text => text.replace(/_+/g, '-');

export const getParentAnchor = url => `/${url.split('/')[1]}`;
export const getChildrenAnchor = url =>  { 
  let splitedUrl = url.split('/')
  return splitedUrl[splitedUrl.length - 1]
};

export const convertToSlug = (text) => {
  if (!text) {
    return '/';
  }
  if (text[0] !== '/') {
    return `/${text}`;
  }
  return text;
};

export const onlyPhoneNumber = number => (number ? number.match(/\+|\d/g).join('') : number);

export const NBSPAfterSingleLetters = (text) => (
	text.replace(/( [A-Za-z]) /g, '$1&#xA0;')
);

export const tagsToReplace = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
};

export const replaceTag = tag => tagsToReplace[tag] || tag;

export const safeTagsReplace = str => str.replace(/[&<>]/g, replaceTag);

export const escapeHtmlWithoutBr = (text) => {
  if (!text) {
    return text;
  }
  const escapedHTML = safeTagsReplace(text);
  return escapedHTML.replace(/(&lt;br&gt;|&lt;\/br&gt;)/g, '<br>');
};

export const isVideo = (fileName) => {
  const ext = (fileName.match(/\.([^.]*?)(?=\?|#|$)/) || [])[1];
  return !!ext.match(/3gp|avi|mov|mp4|m4v|m4a|mp3|mkv|ogv|ogm|ogg|oga|webm|wav/);
};

export const renderMinutesAndSeconds = milliseconds => `${Math.floor(milliseconds / 60000)}:${`0${((milliseconds / 1000) % 60)}`.slice(-2)} s`;
