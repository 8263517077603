import React, { Component } from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import { Bind } from "lodash-decorators";
import WindowStore from "stores/window.store";
import CoreStore from "stores/core.store";
import { HeroSection as HeroSectionModel } from "models";
import Layout from "components/Layout/Layout";
import LinkParser from "components/LinkParser/LinkParser";
import HorizontalLine from "components/HorizontalLine/HorizontalLine";
import { animationEndTrigger } from "common/animations/methods";
import { NBSPAfterSingleLetters } from "common/methods/string";

import Partners from "./Partners/Partners";
import styles from "./HeroSection.module.scss";

export interface HeroSectionProps {
  data: HeroSectionModel;
  windowStore: WindowStore;
  coreStore: CoreStore;
}

@inject("windowStore", "coreStore")
@observer
class HeroSection extends Component<HeroSectionProps> {
  @observable index = 0;

  headerRef: HTMLSpanElement | null = null;

  componentDidMount() {
    this.changeHeadingText();
  }

  @Bind
  @action
  async changeHeadingText() {
    if (this.headerRef) {
      const { headingChangingTexts } = this.props.data;
      this.index =
        this.index >= headingChangingTexts.length - 1 ? 0 : this.index + 1;
      this.headerRef.classList?.replace(
        styles.AnimateEndHeading,
        styles.AnimateStartHeading
      );
      await animationEndTrigger(this.headerRef);
    }
    if (this.headerRef) {
      this.headerRef?.classList?.replace(
        styles.AnimateStartHeading,
        styles.AnimateEndHeading
      );
      await animationEndTrigger(this.headerRef);
      this.changeHeadingText();
    }
  }

  render() {
    const {
      windowStore,
      coreStore,
      data: {
        headingText,
        headingChangingTexts,
        description,
        headingLogo,
        links,
        backgroundAsset,
        mobileBackgroundAsset,
      },
    } = this.props;

    return (
      <div className={styles.Wrapper}>
        <video autoPlay muted loop className={styles.BackgroundVideo}>
          <source
            src={
              windowStore.mediaRegularDesktop
                ? backgroundAsset.url
                : mobileBackgroundAsset.url
            }
            type="video/mp4"
          />
        </video>
        <Layout>
          <div className={styles.Container}>
            <div className={styles.ContainerWithImage}>
              <img
                src={headingLogo}
                alt="legalhut_logo"
                className={styles.Image}
              />
              <div className={styles.MainHeadingContainer}>
                <h1 className={styles.MainHeading}>
                  <span>{headingText}</span>
                  <br />
                  <span
                    className={styles.AnimateStartHeading}
                    ref={(headerRef) => (this.headerRef = headerRef)}
                  >
                    {headingChangingTexts[this.index].headingText}
                  </span>
                </h1>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: NBSPAfterSingleLetters(description),
                }}
                className={styles.MainSmallText}
              />
              <ul className={styles.MainLinksContainer}>
                {links.map((link) => (
                  <li className={styles.MainLink} key={link.text}>
                    <LinkParser data={link}>{link.text}</LinkParser>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <HorizontalLine />
          <Partners partners={coreStore.data.partners} />
        </Layout>
      </div>
    );
  }
}

export default HeroSection as any;
