import React, {  FunctionComponent } from 'react';
import styles from './Button.module.scss';

export interface ButtonProps {
	type?: 'submit' | 'reset' | 'button';
	className?: string;
	onClick?: () => void;
}

const Button: FunctionComponent<ButtonProps> = ({ type = 'button', className, onClick, children }) => (
	<button
		type={type}
		className={`${styles.Button} ${className}`}
		onClick={onClick}
	>
		{children}
	</button>
);

Button.defaultProps = {
	type: 'button',
	className: '',
	onClick: () => null,
};

export default Button;
