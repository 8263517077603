import React, { FunctionComponent } from "react";
import parse, { domToReact } from "html-react-parser";
import styles from "./Markup.module.scss";

interface Props {
  markup: string;
  wrap?: any;
  type?: string;
  trim?: any;
  className?: any;
  components?: any; // TODO: verify what is it supposed to do?
}

const P = ({ children }) => <p className={styles.Paragraph}> {children} </p>;

const H1 = ({ children }) => (
  <h1 className={styles.HeaderExtraLarge}> {children} </h1>
);

const H2 = ({ children }) => (
  <h2 className={styles.HeaderLarge}> {children} </h2>
);

const H3 = ({ children }) => (
  <h3 className={styles.HeaderRegular}> {children} </h3>
);

const EM = ({ children }) => <i> {children} </i>;

const STRONG = ({ children }) => <strong> {children} </strong>;

const UL = ({ children }) => (
  <ul className={styles.UnorderedList}>{children}</ul>
);

const LI = ({ children }) => (
  <li className={styles.ListItem}>
    <span>{children}</span>
  </li>
);

const A = (props) => {
  return (
    <a {...props.attribs} target={"_blank"} rel="noopener noreferrer">
      {props.children[1] || props.children}
    </a>
  );
};

const componentsMapping = {
  a: A,
  p: P,
  h1: H1,
  h2: H2,
  h3: H3,
  li: LI,
  ul: UL,
  em: EM,
  strong: STRONG,
};

const options = {
  replace: function(domNode) {
    if (domNode.name in componentsMapping) {
      const T = componentsMapping[domNode.name];
      return (
        <T attribs={domNode.attribs}>
          {" "}
          {domToReact(domNode.children, options)}{" "}
        </T>
      );
    }
  },
};

export const Markup: FunctionComponent<Props> = (props) => {
  return <div className={props.className}>{parse(props.markup, options)}</div>;
};
