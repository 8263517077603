import get from 'lodash/get';
import ContentItemWithResolver from "models/ContentItemWithResolver";
import Navigator from './Navigator';

export default class InnerLink extends ContentItemWithResolver {
	get text() {
		return get(this, '_text.value', '');
	}

	get link() {
		return get(this, '_slugs.taxonomyTerms[0].name', '');
	}

	get navigator(): Navigator {
		return get(this, '_navigator[0]', null);
	}

	get slug() {
		return get(this, '_slug.taxonomyTerms[0].name', '');
	}
}
