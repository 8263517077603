import React, { FunctionComponent } from "react";
import { FourColumnsWithHeaderSection as FourColumnsWithHeaderSectionModel } from "models";
import Layout from "components/Layout/Layout";
import Column from "components/Column/Column";
import Background from "components/Background/Background";
import { Markup } from "components/Markup/Markup";
import { NBSPAfterSingleLetters } from "common/methods/string";
import styles from "./FourColumnsWithHeaderSection.module.scss";
import Button from "components/Buttons/Button/Button";
import LinkParser from "components/LinkParser/LinkParser";
import Asset from "../../components/Asset/Asset";

export interface FourColumnsWithHeaderSectionProps {
  data: FourColumnsWithHeaderSectionModel;
}

const FourColumnsSectionWithHeader: FunctionComponent<FourColumnsWithHeaderSectionProps> = ({
  data
}) => {
  const {
    bigHeading,
    heading,
    subHeading,
    columns,
    textColor,
    backgroundColor,
    button,
    backgroundAsset,
  } = data;

  return (
    <Background
      data={{ backgroundColor, textColor }}
      className={styles.MainContainer}
    >
      {backgroundAsset &&
        <Asset
          data={backgroundAsset}
          className={styles.AssetContainer}
          assetClassName={styles.BackgroundAsset}
        />
      }
      <Layout className={styles.Container}>
        {bigHeading ? (
          <h2 className={`${textColor === 'secondary__default_text_color_' ? styles.PrimaryColor : ""} ${styles.BigHeading}`}>{bigHeading}</h2>
        ) : null}
        {
          heading &&
          <h3 className={styles.Heading}>
            <Markup markup={NBSPAfterSingleLetters(heading)} type="html" />
          </h3>
        }

        {subHeading && <h4 className={styles.SubHeading}>
          <Markup markup={NBSPAfterSingleLetters(subHeading)} type="html" />
        </h4>}
        <div className={styles.ColumnsContainer}>
          {columns.map(column => (
            <Column
              className={styles.Column}
              key={column.system.id}
              data={column}
            />
          ))}
        </div>
        {button ? (
          <div className={styles.ContactNavButtonWrapper}>
            <LinkParser data={button} >
              <Button className={styles.ContactNavButton}>{button.text} </Button>
            </LinkParser>
          </div>
        ) : null}
      </Layout>
    </Background>
  );
};

export default FourColumnsSectionWithHeader;
