import React, {  FunctionComponent } from 'react';
import { DownloadLink as DownloadLinkModel } from 'models';
import styles from './DownloadLink.module.scss';

export interface DownloadLinkProps {
	className?: string;
	data: DownloadLinkModel;
}

const DownloadLink: FunctionComponent<DownloadLinkProps> = ({ className, data, children }) => {
	const {
		text,
		file,
	} = data;

	return (
		<a
			className={className ? className : styles.Link}
			href={file}
			target='_blank'
			rel='noopener noreferrer'
		>
			{ children || text }
		</a>
	);
};

DownloadLink.defaultProps = {
	className: '',
};

export default DownloadLink;
