import get from 'lodash/get';
import ContentItemWithColorPickers from "models/ContentItemWithColorPickers";

export default class SliderSection extends ContentItemWithColorPickers {
	get slides() {
		return get(this, '_slides', []);
	}

	get headerText() {
		return get(this, '_header_text.value', '');
	}

	get headerSmallText() {
		return get(this, '_header_text_small.value', '');
	}

	get readMoreText() {
		return get(this, '_read_more_text.value', '');
	}

	get backgroundAsset() {
		return get(this, '_background_asset.assets[0]', null);
	}

	get mobileBackgroundAsset() {
		return get(this, '_mobile_background_asset.assets[0]', null);
	}
}
