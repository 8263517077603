import React, { FunctionComponent } from 'react';
import { Markup } from 'components/Markup/Markup';
import { NBSPAfterSingleLetters } from 'common/methods/string';
import styles from './ArticleRichTextParser.module.scss';

export interface RichTextParserProps {
	content?: string;
	className?: string;
	kenticoContent?: any;
	ref?: any;
}

const ArticleRichTextParser: FunctionComponent<RichTextParserProps> = ({ content, kenticoContent, className }) => {
	const contentString = NBSPAfterSingleLetters((kenticoContent && kenticoContent.resolveHtml()) || content);
	return contentString ? (
		<Markup
			className={`${className} ${styles.ArticleContent}`}
			trim={false}
			markup={contentString}
			type="html"
		/>
	) : null;
};

export default ArticleRichTextParser;
