import get from 'lodash/get';
import ContentItemWithColorPickers from './ContentItemWithColorPickers';

export default class MultipleColumnsSection extends ContentItemWithColorPickers {
	get heading() {
		return get(this, '_heading.value', '');
	}

	get subHeading() {
		return get(this, '_subheading.value', '');
	}

	get columns() {
		return get(this, '_columns', []);
	}
}
