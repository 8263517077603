import get from 'lodash/get';
import ContentItemWithResolver from "models/ContentItemWithResolver";

export default class GlobalTranslation extends ContentItemWithResolver {
	get buttonReadArticle(): string {
		return get(this, '_button_read_article.value', '');
	}

	get seeMoreCaseStudies(): string {
		return get(this, '_case_studies_section_title.value', '');
	}
}
