import get from 'lodash/get';
import ContentItemWithResolver from "models/ContentItemWithResolver";

export default class BlockQuote extends ContentItemWithResolver {
	get citeLink() {
		return get(this, '_cite_link.value', '');
	}

	get text() {
		return get(this, '_text.value', '');
	}

	get author() {
		return get(this, '_author.value', '');
	}
}
