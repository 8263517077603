import React, {  Component } from 'react';
import { observer, inject } from 'mobx-react';
import Cookies from 'universal-cookie';
import { Bind } from 'lodash-decorators';
import COOKIE_KEYS from 'common/consts/cookies';
import { observable } from 'mobx';
import CoreStore from 'stores/core.store';
import { Markup } from 'components/Markup/Markup';
import { NBSPAfterSingleLetters } from 'common/methods/string';
import styles from './CookiesModal.module.scss';

export interface CookiesModalProps {
	coreStore: CoreStore;
}

@inject('coreStore')
@observer
class CookiesModal extends Component<CookiesModalProps> {
	cookieProvider: Cookies;
	@observable hasCookie = false;

	constructor(props) {
		super(props);
		this.cookieProvider = new Cookies();
	}

	componentDidMount() {
		this.hasCookie = this.cookieProvider.get(COOKIE_KEYS.COOKIE_AGREEMENT);
	}

	@Bind
	setCookie() {
		this.cookieProvider.set(COOKIE_KEYS.COOKIE_AGREEMENT, true);
		this.hasCookie = true;
	}

	render() {
	const {
		coreStore: {
			data,
		},
	} = this.props;

	return (!data || this.hasCookie) ? null : (
		<div
			className={styles.Container}
		>
			<button
				type="button"
				className={styles.Close}
				onClick={this.setCookie}
			/>
			<p
				className={styles.Text}
			>
				<Markup
					markup={NBSPAfterSingleLetters(data.cookiesAgreement)}
					type="html"
					trim={false}
				/>
			</p>
		</div>
	);
	}
}
export default CookiesModal as any;
