import React, { useState } from 'react';
import Column from 'components/Column/Column';
import {setColor} from 'components/Background/Background';
import DownButton from 'components/Buttons/Arrow/DownButton';
import styles from './ColumnItem.module.scss';

const ColumnItem = ({ column, textColor }) => {
  const [collapsedColumn, handleColapsedColumn] = useState(true);

  return (
    <div className={styles.Wrapper}>
      <Column
        className={`${styles.Column} ${collapsedColumn && styles.ColumnHidden}`}
        contentClassName={styles.ColumnContent}
        data={column}
      />
      <div className={styles.ArrowWrapper}>
        <DownButton
          onClick={() => handleColapsedColumn(!collapsedColumn)}
          style={{borderColor: setColor(textColor)}}
          className={`${styles.CarouselArrow} ${!collapsedColumn && styles.CarouselArrowOpened}`}
        />
      </div>
    </div>
  )
}

export default ColumnItem;
