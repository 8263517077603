import get from 'lodash/get';
import ContentItemWithColorPickers from "models/ContentItemWithColorPickers";

export default class TwoColumnsWithHeaderSection extends ContentItemWithColorPickers {
	get headerText() {
		return get(this, '_header_text.value', '');
	}

	get headingText() {
		return get(this, '_heading_text.value', '');
	}

	get columns() {
		return get(this, '_columns', []);
	}

	get backgroundAsset() {
		return get(this, '_background_asset.assets[0]', null);
	}

	get contactButtonLink() {
		return get(this, '_contact_button_link[0]', {});
	}

}
