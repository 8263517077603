import React, {  Component } from 'react';
import { observer } from 'mobx-react';
import Asset from 'components/Asset/Asset';
import { Slide as SlideModel } from 'models';
import Layout from 'components/Layout/Layout';
import RichTextParser from 'components/RichTextParser/RichTextParser';
import { observable } from 'mobx';
import { Bind } from 'lodash-decorators';
import root from 'window-or-global';
import { animationEndTrigger } from 'common/animations/methods';
import styles from './Slide.module.scss';
import { isMobile } from "react-device-detect";

export interface ISlideProps {
	data: SlideModel;
	className?: string;
	readMoreText: string;
}

@observer
class Slide extends Component<ISlideProps> {

	static defaultProps = {
		className: '',
	};

	@observable showReadMore = true;

	descriptionRef: any;
	layoutRef: any;
	slideRef: any;

	animations = [
		styles.ShowToLeft,
		styles.ShowToRight,
		styles.HideToLeft,
		styles.HideToRight,
		styles.Hide,
		styles.Show,
	];

	componentDidMount() {
		if (root.addEventListener) {
			root.addEventListener('resize', this.scaleDescriptionHeight);
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			if (this.descriptionRef) {
				this.descriptionRef.style.height = '';
			}
			this.determineIfShouldShowReadMoreButton();
		}
	}

	componentWillUnmount() {
		if (root.removeEventListener) {
			root.removeEventListener('resize', this.scaleDescriptionHeight);
		}
		this.descriptionRef.removeEventListener('transitionend', this.determineIfShouldShowReadMoreButton);
	}

	clearAnimations() {
		this.layoutRef.classList.remove(...this.animations);
		this.slideRef.classList.remove(...this.animations);
	}

	@Bind
	async hideToLeft() {
		this.clearAnimations();
		this.layoutRef.classList.add(styles.HideToLeft);
		this.slideRef.classList.add(styles.Hide);
		await animationEndTrigger(this.layoutRef);
	}

	@Bind
	async hideToRight() {
		this.clearAnimations();
		this.layoutRef.classList.add(styles.HideToRight);
		this.slideRef.classList.add(styles.Hide);
		await animationEndTrigger(this.layoutRef);
	}

	@Bind
	async showToLeft() {
		this.clearAnimations();
		this.layoutRef.classList.add(styles.ShowToLeft);
		this.slideRef.classList.add(styles.Show);
		await animationEndTrigger(this.layoutRef);
	}

	@Bind
	async showToRight() {
		this.clearAnimations();
		this.layoutRef.classList.add(styles.ShowToRight);
		this.slideRef.classList.add(styles.Show);
		await animationEndTrigger(this.layoutRef);
	}

	@Bind
	onReadMoreClick() {
		this.showReadMore = false;
		this.scaleDescriptionHeight();
	}

	@Bind
	scaleDescriptionHeight() {
		if (!this.showReadMore && this.descriptionRef) {
			const childRect = this.descriptionRef.firstChild.getBoundingClientRect();
			this.descriptionRef.style.height = `${childRect.height}px`;
		}
	}

	@Bind
	determineIfShouldShowReadMoreButton() {
		if (this.descriptionRef) {
			const parentRect = this.descriptionRef.getBoundingClientRect();
			const childRect = this.descriptionRef.firstChild.getBoundingClientRect();
			this.showReadMore = (childRect.height + 40) > parentRect.height;
		}
	}

	@Bind
	setRef(ref: React.ReactNode) {
		this.layoutRef = ref;
	}

	render() {
		const {
			className,
			data: {
				name,
				position,
				description,
				backgroundImage,
			},
    } = this.props;

		return (
			<div
				className={className}
				ref={(ref) => this.slideRef = ref }
			>
				{isMobile ?
          <Layout
            setRef={this.setRef}
            className={`${styles.Layout}`}
          >
              <div className={styles.AssetContainer}>
                <img className={styles.AssetStyle} src={backgroundImage.url} alt={backgroundImage.name} />
              </div>
              <div className={styles.SlideWrapper}>
                <div className={styles.TextWrapper}> 
                  <div className={styles.SlideTitle}>
                    <h3 className={styles.Name}>{name} - {position}</h3>
                  </div>
                  {this.showReadMore &&
                    <button className={styles.ReadMoreButton} onClick={()=>this.onReadMoreClick()}> ...</button>}
                  {!this.showReadMore && <div
                    ref={(ref) => this.descriptionRef = ref}
                    className={styles.Description}>
                  <RichTextParser content={description} />
                </div>}
              </div>
            </div>
					</Layout> :					
          <Layout
            setRef={this.setRef}
            className={`${styles.Layout}`}
          >
            <div className={styles.SlideWrapper}>
              <div className={styles.TextWrapper}> 
              <div className={styles.SlideTitle}>
                <div className={styles.SlideTitleTexts}>
                  <h3 className={styles.Name}>{name} - {position}</h3>
                </div>
              </div>
              <div
                ref={(ref) => this.descriptionRef = ref}
                className={styles.Description}>
                <RichTextParser content={description} />
              </div>
            </div>
          <Asset
            className={`${styles.AssetContainer} `}
            assetClassName={`${styles.AssetStyle} `}
            data={backgroundImage}
          />
          </div>
				</Layout>}
			</div>
		);
	}
}

export default Slide;
