import get from 'lodash/get';
import ContentItemWithResolver from "models/ContentItemWithResolver";

export default class PageNotFound extends ContentItemWithResolver {
	get links() {
		return get(this, '_links', []);
	}

	get text() {
		return get(this, '_text.value', '');
	}

	get textBeforeLinks() {
		return get(this, '_text_before_links.value', '');
	}
}
