import React, { FunctionComponent } from 'react';
import Carousel from 'components/Carousel/Carousel';
import { PartnersSection as PartnersSectionModel } from 'models';
import styles from './PartnersSection.module.scss';
import Layout from '../../components/Layout/Layout';
import HorizontalLine from "components/HorizontalLine/HorizontalLine";

export interface PartnersProps {
	data: PartnersSectionModel;
	className?: string;
}

const PartnersSection: FunctionComponent<PartnersProps> = ({ className, data }) => {
	const carouselSettings = {
		className: styles.PartnersContainer,
		slidesToShow: 6,
		slidesToScroll: 1,
		autoplaySpeed: 1500,
		infinite: true,
		autoplay: true,
		arrows: false,
		responsive: [
			{
				breakpoint: 1100,
				settings: {
					slidesToShow: 3.1,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2.1,
				},
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 2.1,
				},
			},
		],
	};

	const { partnerLogos } = data;

	if (!partnerLogos) {
		return null;
	}

	return (
		<Layout className={className || styles.Container}>
			<HorizontalLine/>
			<div className={styles.CarouselContainer}>
				<Carousel
					settings={carouselSettings}
				>
					{partnerLogos.map((partner, index) => (
						<div
							className={styles.PartnerBox}
							key={index}
						>
							<div className={styles.PartnerLogoBox}>
								{partner.link ?
									<a className={styles.Link} href={partner.link} rel="noopener noreferrer" target='_blank'>
										<img
											className={styles.PartnerLogo}
											src={partner.file}
											alt={`${partner.fileName} logo`}
										/>
									</a>
									:
									<img
										className={styles.PartnerLogo}
										src={partner.file}
										alt={`${partner.fileName} logo`}
									/>
								}
							</div>
						</div>
					))}
				</Carousel>
			</div>
		</Layout>
	);
};

export default PartnersSection;
