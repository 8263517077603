import { observable, action } from 'mobx';
import ViewService from 'services/view.service';
import CoreStore from 'stores/core.store';
import View from 'models/View';

class ViewStore {
	@observable currentView: View | null = null;

	@observable loadedViews: Map<string, any> = new Map();

	@observable isViewLoading = false;

	constructor(private coreStore: CoreStore) {}

	@action.bound
	async loadView(slug: string) {
		let view = this.loadedViews.get(slug);
		
		if (!view) {
			this.setViewLoading(true);
			try {
				view = await ViewService.fetchView(slug);

				if (view) {
					this.loadedViews.set(slug, view);
					this.currentView = view;
				}
			} catch (e) {
				console.error(`Failed to fetch the view: `, e);
			}
			this.setViewLoading(false);
		}

		if (view) {
			this.coreStore.setContainerFound(true);
		} else {
			this.coreStore.setContainerFound(false);
		}

		this.currentView = view;
	}

	@action
	setViewLoading(isViewLoading) {
		this.isViewLoading = isViewLoading;
	}
}

export default ViewStore;
