import React, { FunctionComponent } from 'react';
import { PhoneLink as PhoneLinkModel } from 'models';
import styles from './PhoneLink.module.scss';

export interface PhoneLinkProps {
	className?: string;
	data: PhoneLinkModel;
}

const PhoneLink: FunctionComponent<PhoneLinkProps> = ({ className, data }) => {
	return (
		<a
			className={className || styles.Link}
			href={`tel:${data.phoneNumber}`}
		>
			{data.linkText}
		</a>
	);
};

PhoneLink.defaultProps = {
	className: '',
};

export default PhoneLink;
