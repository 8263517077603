import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';
import CollaborationItem from './CollaborationItem';

export default class CollaborationSection extends ContentItemWithResolver {
	get headerText(): string {
		return get(this, '_section_title.value', '');
  }

  get backgroundImage(): string {
		return get(this, '_section_title.value', '');
  }

  get collaborationsItems(): any[] {
    const collaborationItems = this._collaborations_items.map(collItem => new CollaborationItem(collItem));
    return collaborationItems;
  }

    get contactButtonLink() {
        return get(this, '_contact_button_link[0]', {});
    }
}
