import React, {  FunctionComponent } from 'react';
import styles from './LawTag.module.scss';

export interface LawTagProps {
	className?: string;
}

export const LawTag: FunctionComponent<LawTagProps> = ({ className, children }) => {
	return (
		<span className={`${className} ${styles.LawTag}`}>
			{children}
		</span>
	);
};
