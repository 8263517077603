import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Helmet } from 'react-helmet';
import { dropElement } from 'common/animations/animejs';
import LinkParser from 'components/LinkParser/LinkParser';
import CoreStore from "stores/core.store";
import styles from './PageNotFound.module.scss';

export interface PageNotFoundProps {
	coreStore: CoreStore;
}

@inject('coreStore')
@observer
class PageNotFound extends Component<PageNotFoundProps> {
	descriptionRef: any;
	errorCodeRef: any;
	linksRef: any;

	componentDidMount() {
		dropElement(this.errorCodeRef.childNodes, (el, i) => 600 + i * 300);
		setTimeout(() => {
			if (this.linksRef) {
				this.linksRef.style.opacity = 1;
			}
		}, 800);
		setTimeout(() => {
			if (this.descriptionRef) {
				this.descriptionRef.style.opacity = 1;
			}
		}, 600);
	}

	render() {
		const {
			coreStore: {
				data: {
					pageNotFound: {
						links,
						text,
						textBeforeLinks,
					},
				},
			},
		} = this.props;

		return (
			<div className={styles.Container}>
				<Helmet>
					<meta name='prerender-status-code' content='404'/>
				</Helmet>
				<div
					ref={(ref) => this.errorCodeRef = ref}
					className={styles.ErrorCode}
				>
					<div className={styles.Number}>4</div>
					<div className={styles.Number}>0</div>
					<div className={styles.Number}>4</div>
				</div>
				<p
					ref={(ref) => this.descriptionRef = ref}
					className={`${styles.Description} ${styles.Underlined}`}
				>
					{text}
				</p>
				<div className={styles.LinksWrapper}>
					<div
						className={styles.UsefulLinks}
						ref={(ref) => this.linksRef = ref}
					>
						{
							textBeforeLinks && (
								<p className={styles.LinksHeader}>
									{textBeforeLinks}
								</p>
							)
						}
						{links && links.map((link) => (
							<LinkParser
								data={link}
								className={styles.Link}
								key={link.id}
							>
								{link.text}
							</LinkParser>
						))}
					</div>
				</div>
			</div>
		);
	}
}

export default PageNotFound as any;
