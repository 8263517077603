import root from "window-or-global";
import convertBlobToBase64 from "common/methods/convertBlobToBase64";

const URLContactForm = process.env.REACT_APP_CONTACT_FORM_MAIL_TO;
const URLNewsletterForm = process.env.REACT_APP_NEWSLETTER_FORM;

class MailingService {
  static async sendContactForm({
    contactReasonText,
    contactReasonValue,
    name,
    email,
    company,
    phone,
    message,
    url,
    source,
    referrer,
    // TO DO: uncomment when voice messages fixed
    // voiceMessage,
    commercialInfoCheckBox,
    directMarketingCheckBox,
  }) {
    if (!URLContactForm) {
      return Promise.reject("No env: REACT_APP_CONTACT_FORM_MAIL_TO")
    }

    // Uncomment when MailChimp ready
    // if (!URLNewsletterForm) {
    //   return Promise.reject("No env: URLNewsletterForm")
    // }

    let body = JSON.stringify({
      contactReasonText,
      contactReasonValue,
      name,
      email,
      company,
      phone,
      message,
      url,
      source,
      referrer,
      commercialInfoCheckBox,
      directMarketingCheckBox,
    });
    // TO DO: uncomment when voice messages fixed
    // if (voiceMessage) {
    // 	const vmsg = await convertBlobToBase64(voiceMessage);
    // 	body = JSON.stringify({
    // 		name,
    // 		email,
    // 		company,
    // 		phone,
    // 		message,
    // 		url,
    // 		source,
    // 		referrer,
    // 		vmsg,
    // 	});
    // }

    return root
      .fetch(URLContactForm, {
        method: "POST",
        body,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => (res.ok ? true : Promise.reject(res)));
  }

  static async saveNewsletterEmail(email) {
    console.log('object');
    const body = JSON.stringify(email);

    return root
      .fetch(URLNewsletterForm, {
        method: "POST",
        body,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => (res.ok ? true : Promise.reject(res)));
  }
}

export default MailingService;
