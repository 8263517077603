import get from "lodash/get";
import ContentItemWithResolver from "models/ContentItemWithResolver";

export default class LinkedImage extends ContentItemWithResolver {
  get link() {
    return get(this, "_link.value", "");
  }

  get image() {
    return get(this, "_image.assets[0]", null);
  }

  get newWindow() {
    return get(this, "_new_window.value[0].name", false) === "Yes";
  }

  get internalRouting() {
    return get(this, "_internal_routing.value[0].name", false) === "Yes";
  }
}
