import get from 'lodash/get';
import ContentItemWithColorPickers from "models/ContentItemWithColorPickers";

export default class SliderSectionWithHeader extends ContentItemWithColorPickers {
	get slides() {
		return get(this, '_slides', []);
	}

	get headerText() {
		return get(this, '_header_text.value', '');
	}

	get readMoreText() {
		return get(this, '_read_more_text.value', '');
	}

	get contactButtonLink() {
		return get(this, '_contact_button_link[0]', {});
	}
}
