import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import BlogStore from "stores/blog.store";
import Layout from "components/Layout/Layout";
import SectionsFilter from 'blocks/BlogListing/SectionsFilter/SectionsFilter';
import { ArticleBox } from 'blocks/ArticleBox/ArticleBox';
import Pagination from "blocks/BlogListing/Pagination/Pagination";
import Newsletter from "blocks/Newsletter/Newsletter";
import PromoSection from "blocks/PromoSection/PromoSection";
import CoreStore from "stores/core.store";
import { show } from "common/animations/animejs";
import styles from './BlogListing.module.scss';

export interface BlogListingProps {
	blogStore: BlogStore; // TODO typing
	coreStore: CoreStore; // TODO typing
	data; // TODO typing
}

@inject('coreStore', 'blogStore')
@observer
class BlogListing extends Component<BlogListingProps> {

	listingContentRef: any;

	constructor(props) {
		super(props);
		props.blogStore.getArticleList();
	}

	componentDidUpdate(prevProps) {
		show(this.listingContentRef, 300);
	}

	render() {
		const {
			coreStore: {
				data: {
					newsletter,
				},
			},
			blogStore: {
				pagedArticleList,
				articleListLoaded,
				articlesPerPage,
			},
			data: {
				blogHeader,
				blogSubHeader,
				promoSection,
				filterSelectAllText,
			},
		} = this.props;

		const articlesInRow = articlesPerPage / 3;

		return (
			<Layout>
				<header className={styles.BlogHeader}>
					{blogHeader}
					<div className={styles.BlogSubHeader}>
						{blogSubHeader}
					</div>
				</header>
				<SectionsFilter
					noValueText={filterSelectAllText}
				/>
				<div
					ref={(ref) => this.listingContentRef = ref}
				>
					<section className={styles.ArticleBoxesContainer}>
						{articleListLoaded && pagedArticleList.slice(0, articlesInRow).map((articleData) => (
							<ArticleBox
								className={styles.ArticleBox}
								data={articleData}
								key={articleData.id}
							/>
						))}
					</section>

					{newsletter && <Newsletter className={styles.InnerSection} data={newsletter} />}

					<section className={styles.ArticleBoxesContainer}>
						{articleListLoaded && pagedArticleList.slice(articlesInRow, articlesInRow * 2).map((articleData) => (
							<ArticleBox
								className={styles.ArticleBox}
								data={articleData}
								key={articleData.id}
							/>
						))}
					</section>

					{promoSection && <PromoSection className={styles.InnerSection} data={promoSection} />}

					<section className={styles.ArticleBoxesContainer}>
						{articleListLoaded && pagedArticleList.slice(articlesInRow * 2, articlesInRow * 3).map((articleData) => (
							<ArticleBox
								className={styles.ArticleBox}
								data={articleData}
								key={articleData.id}
							/>
						))}
					</section>
				</div>
				<Pagination />
			</Layout>
		);
	}
}

export default BlogListing as any;
