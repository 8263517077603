import React, {  FunctionComponent } from 'react';
import { OuterLink as OuterLinkModel } from 'models';
import styles from './OuterLink.module.scss';

export interface OuterLinkProps {
	className?: string;
	data: OuterLinkModel;
}

const OuterLink: FunctionComponent<OuterLinkProps> = ({ className, data, children }) => {
	const {
		text,
		link,
		newWindow,
		file,
	} = data;

	return (
		<a
			className={className ? className : styles.Link}
			href={link || file}
			target={newWindow ? '_blank' : '_self'}
			rel="noopener noreferrer nofollow"
		>
			{ children || text }
			</a>
	);
};

OuterLink.defaultProps = {
	className: '',
};

export default OuterLink;
