import React, { useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { SliderWithIcon as SliderModel } from 'models';
import Layout from 'components/Layout/Layout';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import Buttons from './Buttons/Buttons';
import Carousel from 'components/Carousel/Carousel';
import { SlideRef } from 'common/types/types';
import { isMobile } from 'react-device-detect';
import SLIDER_SETTINGS, {
  ICON_SLIDER_AUTOPLAYSPPED,
  AUTOPLAY,
  ANIMATION_TIME,
} from './consts/sliderSettings';
import Slide from './Slide/Slide';
import styles from './SliderWithIcon.module.scss';
import LinkParser from "../../components/LinkParser/LinkParser";
import Button from "../../components/Buttons/Button/Button";

export interface SliderProps {
  data: SliderModel;
}

const SliderWithIcon = observer((props: SliderProps) => {
  const [currentSlideIndex, setSlide] = useState<number>(0);
  const [autoPlay, setAutoPlay] = useState<boolean>(true);
  const slideRef = useRef<SlideRef>(null);
  const buttonsRef = useRef<any>(null);
  const {
    data: { header, sliders, buttons, contactButtonLink }
  } = props;

  const setCurrentSlide = (slideIndex: number) => {
    setSlide(slideIndex);
    if (slideRef && slideRef.current) {
      slideRef.current.slickGoTo(slideIndex);
      buttonsRef.current.slickGoTo(slideIndex);
      setAutoPlay(true);
    }
  };

  const sliderSettings = SLIDER_SETTINGS(autoPlay);
  const autoPlaySpeed = true;

  return (
    <Layout className={styles.Slider}>
        {header ? <SectionTitle title={header}/>: null}
      <Carousel
        {...sliderSettings}
        autoplaySpeed={
          autoPlaySpeed
            ? AUTOPLAY
            : ICON_SLIDER_AUTOPLAYSPPED
        }
        slideRef={slideRef}
        beforeChange={(before, next) => {
          setCurrentSlide(next);
        }}
        draggable={isMobile}
        fade={!isMobile}
        className={styles.Carousel}
      >
        {sliders.map((slide, index) => (
          <Slide
            data={slide}
            slideNumber={index + 1}
            currentSlideIndex={currentSlideIndex}
            className={styles.Sliders}
            key={slide.system.id}
            changeSlide={setCurrentSlide}
          />
        ))}
      </Carousel>
      <Buttons
        data={buttons}
        isMobile={isMobile}
        slideRef={buttonsRef}
        onClick={setCurrentSlide}
        className={`${styles.Buttons}`}
        activeSlideIndex={currentSlideIndex}
        isPaused={!autoPlay}
        isShown={autoPlay}
        animationTime={
          autoPlaySpeed ? ANIMATION_TIME : '10s'
        }
      />
        <div className={styles.BottomContactButtonSection}>
            { contactButtonLink ? (
                <LinkParser
                    data={contactButtonLink}
                    className={styles.BottomContactButtonSection__ButtonPosition}
                >
                    <Button type="button">
                        {contactButtonLink.text}
                    </Button>
                </LinkParser>
            ) : null
            }
        </div>
    </Layout>
  );
});

export default SliderWithIcon;
