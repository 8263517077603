import root from 'window-or-global';
import LANGUAGES, { 
  LANGUAGE_STORAGE_KEY, 
  isLanguageValue,
  POLISH,
  ENGLISH,
 } from 'common/consts/languages';
 import ANCHORS from 'common/consts/anchor';
 import SLUG from 'common/consts/slug';

class LanguageService {
  static DEFAULT_LANGUAGE = LANGUAGES.get('PL');
  static EN_LANGUAGE = LANGUAGES.get('EN');

  static isBlogPathName() {
    const locationSplit = root.location.pathname.split('/');
    return locationSplit.length > 1
      && (`/${locationSplit[1]}` === ANCHORS.BLOG || `/${locationSplit[2]}` === ANCHORS.BLOG);
  }
  static isCaseStudiesPathName() {
    const locationSplit = root.location.pathname.split('/');
    return locationSplit.length > 1
      && (`/${locationSplit[1]}` === ANCHORS.CASE_STUDIES || `/${locationSplit[2]}` === ANCHORS.CASE_STUDIES);
  }
  static getSelectedBasenameValue() {
    const selectedValue = LanguageService.getSelectedLanguage();
    return selectedValue === LanguageService.DEFAULT_LANGUAGE
      ? ''
      : selectedValue;
  }

  static languageValues() {
    return Array.from(LANGUAGES.values());
  }

  static getLanguageByValue(value) {
    return this.languageValues()
      .find(language => language === value);
  }
  static getSelectedLanguage() {
    if (this.isBlogPathName() || this.isCaseStudiesPathName()) { // FOR BLOG AND CASE STUDIES ALWAYS SET POLISH LANGUAGE
      return LanguageService.DEFAULT_LANGUAGE;
    }

    const storedaLnguageValue = root.localStorage.getItem(LANGUAGE_STORAGE_KEY);
    const languageFromLocalStorage = LanguageService.getLanguageByValue(storedaLnguageValue);
   
    if (languageFromLocalStorage) {
      return languageFromLocalStorage;
    }
    return LanguageService.DEFAULT_LANGUAGE;
  }

  static getCurrentLanguage() {
    const selectedLanguage = LanguageService.getSelectedLanguage();
    let currentLanguage = ''
    if (selectedLanguage === LanguageService.DEFAULT_LANGUAGE) {
      currentLanguage = ENGLISH;
    } else {
      currentLanguage = POLISH;
    }
    return currentLanguage;
  }

  static setCurrentLanguage() {
    const currentLanguage = LanguageService.getCurrentLanguage();
    if (currentLanguage === ENGLISH) {
       return LanguageService.setLanguage(LanguageService.EN_LANGUAGE);
    } else {
      return LanguageService.setLanguage(LanguageService.DEFAULT_LANGUAGE);
    }
  } 

  static setLanguage(selectedLanguage) {
    root.localStorage.setItem(LANGUAGE_STORAGE_KEY, selectedLanguage);
    root.location.replace(LanguageService.getCurrentLanguagePathName());
  }

  static prependLanguageToPathName(language) {
    const pathname = root.location.pathname;
    const currentPathNameArray = pathname.split('/');
    const isBlog = LanguageService.isBlogPathName();
    const currentSlug = isLanguageValue(currentPathNameArray[1]) ? pathname.replace(`/${currentPathNameArray[1]}/`, '') : pathname.replace('/', '') ;
    const subpageSlug =
        currentSlug.split('/').length > 1
            ? currentPathNameArray[1]
            : currentSlug;
    const slugObjectKey = Object.keys(SLUG).find((slugObjKey) => {
      const slugObj = SLUG[slugObjKey];
      return Object.keys(slugObj).some((slugItemKey) => slugObj[slugItemKey] === currentSlug);
    });
    if (!slugObjectKey) {
      return  isBlog ? undefined : language
    }
    const slugObject = SLUG[slugObjectKey];
    const translatedSlug = slugObject ? slugObject[language] : currentSlug;

    if (isLanguageValue(currentPathNameArray[1])) {
      if (language === LanguageService.DEFAULT_LANGUAGE) {
        currentPathNameArray.splice(1, 1);
      } else if (language !== currentPathNameArray[1]) {
        currentPathNameArray[1] = language;
      }
    } else if (language !== LanguageService.DEFAULT_LANGUAGE) {
      currentPathNameArray.unshift('');
      currentPathNameArray[1] = language;
    }

    if (subpageSlug !== '' && currentSlug === translatedSlug) {
      return currentPathNameArray.join('/');
    }
    return currentPathNameArray.join('/').replace(subpageSlug, translatedSlug);
  }

  static getCurrentLanguagePathName() {
    return this.prependLanguageToPathName(LanguageService.getSelectedLanguage());
  }
}

export default LanguageService;
