import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';

export default class RichTextWithTitle extends ContentItemWithResolver {
	get title(): string {
		return get(this, '_title.value', '');
	}

	get content() {
		return get(this, '_content', null);
	}
}
