import React from 'react';
import { observer } from 'mobx-react';
import { SliderButtonsSection as ButtonsSectionModel } from 'models';
import Carousel from 'components/Carousel/Carousel';
import Button from './Button/Button';
import HorizontalLine from '../HorizontalLine/HorizontalLine';
import styles from './Buttons.module.scss';
import { MULIPLE_COLUMN_SLIDER_SETTINGS } from '../consts/sliderSettings';

export interface ButtonsProps {
  data: ButtonsSectionModel;
  className?: string;
  onClick: any;
  isTeamSlide?: boolean;
  activeSlideIndex: number;
  isShown?: boolean;
  isPaused?: boolean;
  animationTime?: string;
  slideRef: React.ReactNode | null;
  isMobile: boolean;
}

const Buttons = observer((props: ButtonsProps) => {
  const {
    data: {
      sliderButtons,
    },
    className,
    onClick,
    activeSlideIndex,
    isShown= false,
    isPaused,
    animationTime,
  } = props;

  const onButtonClick = (index: number) => {
    onClick(index);
  };

  const sliderSettings = MULIPLE_COLUMN_SLIDER_SETTINGS(true);

  return (
    <Carousel
      {...sliderSettings}
      afterChange={(index: number) => onButtonClick(index)}
      infinite
      autoplaySpeed={8000}
      autoplay
      slideRef={props.slideRef}
      slidesToScroll={1}
      className={`${className} ${styles.SliderButtons}`}>
      {sliderButtons && sliderButtons.map(({ title }, index) => (
        <div key={title} className={styles.ButtonWrapper}>
          <Button
            className={`${styles.StageButton} ${activeSlideIndex === index ? styles.Active : ''}`}
            onClick={() => onButtonClick(index)}
          >
            {title || index}
          </Button>
          <HorizontalLine
            isShown={isShown && index === activeSlideIndex}
            isPaused={isPaused && index === activeSlideIndex}
            animationTime={animationTime}
          />
        </div>
      ))}
    </Carousel>
  );
});

export default Buttons;
