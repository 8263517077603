import { KENTICO_TYPES } from "common/consts/kentico";
import { SortOrder } from "kentico-cloud-delivery";
import Client from "./kenticoClient";

class CaseStudiesService {
  static async fetchArticle(slug: string) {
    const items = await Client.items()
      .type(KENTICO_TYPES.CASE_STUDY_ARTICLE)
      .depthParameter(10)
      .equalsFilter("elements.slug", slug)
      .getPromise();

    if (items.isEmpty) {
      return null;
    }

    return items.lastItem;
  }

  static async fetchArticleList() {
    const request = Client.items()
      .type(KENTICO_TYPES.CASE_STUDY_ARTICLE)
      .depthParameter(0)
      .elementsParameter([
        "categories_case_studies__case_studies_sections",
        "law_categories_case_studies__case_studies_law",
        "main_category_case_studies__case_studies_branch_of_law",
        "image",
        "title",
        "lead",
        "slug"
      ])
      .orderParameter("elements.publication_date", SortOrder.desc);

    const response = await request.getPromise();

    if (response.isEmpty) {
      return [];
    }

    return response.items;
  }
}

export default CaseStudiesService;
