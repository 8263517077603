import root from 'window-or-global';

export const animationEndTrigger = (element) => {
	return new Promise<boolean>((resolve) => {
		const onAnimationEnd = () => {
			element.removeEventListener('animationend', onAnimationEnd);
			resolve();
		};
		element.addEventListener('animationend', onAnimationEnd);
	});
};

// when from < 0 - START FLOATING
// when to < 0 - STOP FLOATING
export const verticleFloatElementInBounds = (element, from, to) => {
	if (from < 0 && to >= 0) {
		root.requestAnimationFrame(() => {
			element.style.transform = `translateY(${-from}px)`;
		});
		return true;
	} else if (from > 0 && to >= 0) {
		element.style.transform = `none`;
	}
	return false;
};
