import React, {  Component } from 'react';
import { inject, observer } from 'mobx-react';
import CoreStore from 'stores/core.store';
import { Navbar as NavbarModel } from 'models';
import DesktopNav from './DesktopNav/DesktopNav';
import MobileNav from './MobileNav/MobileNav';
import styles from './Navbar.module.scss';

export interface NavbarProps {
	data: NavbarModel;
	coreStore?: CoreStore;
}

@inject('coreStore')
@observer
class Navbar extends Component<NavbarProps> {
	render() {
		const {
			data: {
				contactButtonLink,
				links,
				linkedInLink,
				youTubeLink,
				facebookLink,
			},
		} = this.props;

		return (
			<nav>
				<DesktopNav
					contactButtonLink={contactButtonLink}
					linkedInLink={linkedInLink}
					youTubeLink={youTubeLink}
					facebookLink={facebookLink}
					links={links}
					className={styles.DesktopNav}
				/>

				<MobileNav
					contactButtonLink={contactButtonLink}
					links={links}
					linkedInLink={linkedInLink}
					youTubeLink={youTubeLink}
					facebookLink={facebookLink}
					className={styles.MobileNav}
				/>
			</nav>
		);
	}
}

export default Navbar;
