import React, {  FunctionComponent } from 'react';
import styles from './Layout.module.scss';

export interface LayoutProps {
	className?: string;
	setRef?: (r) => any;
}

const Layout: FunctionComponent<LayoutProps> = ({ children, className, setRef }) => {
	return (
		<div
			className={className}
			ref={(r) => setRef && setRef(r)}
		>
			<div className={styles.Grid}>
				{children}
			</div>
		</div>
	);
};

export default Layout;