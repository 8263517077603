import React, {  FunctionComponent } from 'react';
import { MultipleColumnsSection as MultipleColumnsSectionModel } from 'models';
import Layout from 'components/Layout/Layout';
import Column from 'components/Column/Column';
import Background from 'components/Background/Background';
import { Markup } from 'components/Markup/Markup';
import { NBSPAfterSingleLetters } from 'common/methods/string';
import styles from './MultipleColumnsSection.module.scss';

export interface MultipleColumnsSectionProps {
	data: MultipleColumnsSectionModel;
}

const MultipleColumnsSection: FunctionComponent<MultipleColumnsSectionProps> = ({ data }) => {
	const {
		heading,
		subHeading,
		columns,
		textColor,
		backgroundColor,
	} = data;

	return (
		<Background
			data={{backgroundColor, textColor}}
			className={styles.Container}
		>
			<Layout>
				<h1
					className={styles.Heading}
				>
					<Markup
						markup={NBSPAfterSingleLetters(heading)}
						type="html"
					/>
				</h1>
				<h2
					className={styles.SubHeading}
				>
					<Markup
						markup={NBSPAfterSingleLetters(subHeading)}
						type="html"
					/>
				</h2>
				<div className={styles.ColumnsContainer}>
					{columns.map((column) => (
						<Column
							className={styles.Column}
							key={column.system.id}
							data={column}
						/>
					))}
				</div>
			</Layout>
		</Background>
	);
};

export default MultipleColumnsSection;
