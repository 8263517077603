import React, { Component } from "react";
import { FooterSection as FooterSectionModel } from 'models';
import { inject, observer } from 'mobx-react';
import Layout from 'components/Layout/Layout';
import Background from 'components/Background/Background';
import { Markup } from 'components/Markup/Markup';
import LinkParser from 'components/LinkParser/LinkParser';
import { NBSPAfterSingleLetters } from 'common/methods/string';
import WindowStore from 'stores/window.store';

import styles from './FooterSection.module.scss';

export interface FooterProps {
	data: FooterSectionModel;
	windowStore: WindowStore;
}

@inject('windowStore')
@observer
class FooterSection extends Component<FooterProps> {
	render() {
		const {
			windowStore,
			data: {
				description,
				links,
				bottomLinks,
				bottomText,
				mobileBottomText,
				backgroundColor,
				textColor,
				logo,
			},
		} = this.props;

		return (
			<Background
				className={styles.DefaultColors}
				data={{ backgroundColor, textColor }}
			>
				<Layout className={styles.LayoutStyle}>
					<div className={styles.Description}>
						<Markup
							markup={NBSPAfterSingleLetters(description)}
							type="html"
						/>
					</div>
					<ul className={styles.MainLinksContainer}>
						<div className={styles.LogoWrapper}>
						    <img src={logo.url} alt={logo.name} className={styles.Logo} />
						</div>
						{links.map((link) => (
							<li className={styles.LinkContainer} key={link.text}>
								<LinkParser data={link} className={styles.MainLink}>
									{link.text}
								</LinkParser>
							</li>
						))}
					</ul>
					<ul className={styles.MainLinksContainer}>
						{bottomLinks.map((link) => (
							<li className={styles.LinkContainer} key={link.text}>
								<LinkParser data={link} className={styles.BottomLink}>
									{link.text}
								</LinkParser>
							</li>
						))}
					</ul>
					<div className={styles.BottomText}>
						{windowStore && windowStore.mediaTablet
							?	<Markup
									markup={NBSPAfterSingleLetters(bottomText)}
									type="html"
									trim={false}
								/>
							: <Markup
									markup={NBSPAfterSingleLetters(mobileBottomText)}
									type="html"
									trim={false}
								/>}
					</div>
				</Layout>
			</Background>
		);
	}
}

export default FooterSection as any;
