import get from 'lodash/get';
import ContentItemWithColorPickers from "models/ContentItemWithColorPickers";

export default class FooterSection extends ContentItemWithColorPickers {
	get description(): string {
		return get(this, '_description.value', '');
	}

	get links(): any[] {
		return get(this, '_links', []);
	}

	get bottomLinks(): any[] {
		return get(this, '_bottom_links', []);
	}

	get bottomText(): string {
		return get(this, '_bottom_text.value', '');
	}

	get mobileBottomText(): string {
		return get(this, '_mobile_bottom_text.value', '');
	}

	get logo() {
		return get(this, '_logo.value[0]', '');
	}
}
