import { KENTICO_TYPES } from 'common/consts/kentico';
import { slashesToDashes } from 'common/methods/string';
import Client from './kenticoClient';

class ViewService {
	static async fetchView(slug: string) {
		const slugCodeName = `_${slashesToDashes(slug)}`;

		const items = await Client.items()
			.type(KENTICO_TYPES.VIEW)
			.containsFilter('elements.slug', [slugCodeName])
			.depthParameter(10)
			.getPromise();

		if (items.isEmpty) {
			return null;
		}
		return items.lastItem;
	}
}

export default ViewService;
