import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';

export default class TestimonialsSection extends ContentItemWithResolver {
	get headerText(): string {
		return get(this, '_section_title.value', '');
  }
  get testimonialsItems(): any[] {
    return get(this, '_testimonials_items', []);
  }
};
