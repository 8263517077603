import React, { FunctionComponent } from 'react';
import ArrowButtonProps from 'components/Buttons/Arrow/Props';
import styles from './Arrow.module.scss';

const DownButton: FunctionComponent<ArrowButtonProps> = ({ onClick, className, style }) => {
	return (
		<button
			onClick={onClick}
			className={`${className} ${styles.ArrowButton}`} >
			<i
				style={style || {}}
				className={`${styles.Arrow} ${styles.Down}`}
			/>
		</button>
	);
};
DownButton.defaultProps = {
	className: '',
	style: '',
	onClick: () => null,
};

export default DownButton;
