import get from 'lodash/get';
import ContentItemWithResolver from "models/ContentItemWithResolver";
import Navbar from "models/Navbar";
import Translations from './Translations';
import FooterSection from "models/FooterSection";
import Newsletter from "models/Newsletter";

export default class Core extends ContentItemWithResolver {
	get navigation(): Navbar {
		return get(this, '_navbar[0]', null);
	}

	get logo() {
		return get(this, '_logo.assets[0]', null);
	}

	get smallLogo() {
		return get(this, '_small_logo.assets[0]', null);
	}

	get cookiesAgreement() {
		return get(this, '_cookies_agreement.value', '');
	}

	get partners() {
		return get(this, '_partners[0]', []);
	}

	get translations(): Translations {
		return get(this, '_translations[0]', []);
	}

	get newsletter(): Newsletter {
		return get(this, '_newsletter[0]', null);
	}

	get footer(): FooterSection {
		return get(this, '_footer[0]', null);
	}

	get pageNotFound() {
		return get(this, '_page_not_found[0]', null);
	}

	get wahtWeDo() {
		return get(this, '_what_we_do[0]', null);
	}

	get contactEmployee() {
		return get(this, '_contact_employee[0]', null);
	}

	get ctaContactSection() {
		return get(this, '_cta_contact_section[0]', null);
	}
}
