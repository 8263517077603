import React, { Component } from 'react';
import { BlogAuthor as BlogAuthorModel } from 'models';
import LinkParser from "components/LinkParser/LinkParser";
import { Markup } from 'components/Markup/Markup';
import { NBSPAfterSingleLetters } from "common/methods/string";
import Asset from "components/Asset/Asset";
import { Link } from "components/Link/Link";
import WindowStore from "stores/window.store";
import { observer, inject } from 'mobx-react';
import styles from './BlogAuthor.module.scss';

export interface BlogAuthorProps {
	windowStore?: WindowStore;
	data: BlogAuthorModel;
}

@inject('windowStore')
@observer
class BlogAuthor extends Component<BlogAuthorProps> {

	render() {
		const {
			windowStore,
			data,
		} = this.props;
		return (
			<aside className={styles.Container}>
				<div
					className={styles.ContentContainer}
				>
					<h2 className={styles.Name}>
						{data.authorData.name}
						<br />
						<span className={styles.Position}>
						{data.authorData.position}
					</span>
					</h2>
					{!windowStore?.mediaMobile && (
						<Asset
							className={styles.PictureContainer}
							data={data.smallPicture}
						/>
					)}
					<p className={styles.Description}>
						<Markup
							markup={NBSPAfterSingleLetters(data.authorData.description)}
							type="html"
							wrap={false}
						/>
					</p>
					{/* TO DO: uncomment when links fixed */}
					{/* <LinkParser data={data.link}>
						<Link>{data.link.text}</Link>
					</LinkParser> */}
				</div>
				{windowStore?.mediaMobile && (
					<Asset
						className={styles.PictureContainer}
						data={data.smallPicture}
					/>
				)}
			</aside>
		);
	}
}

export default BlogAuthor;
