import React, {  FunctionComponent } from 'react';
import OuterLink from 'models/OuterLink';
import Icon from 'components/Icon/Icon';
import faLinkedinIn from '@fortawesome/fontawesome-free-brands/faLinkedinIn';
import LinkParser from 'components/LinkParser/LinkParser';

export interface LinkedInLinkProps {
	data: OuterLink;
	className?: string;
}

const LinkedInLink: FunctionComponent<LinkedInLinkProps> = ({ data, className }) => {
	return (
		<LinkParser
			className={className}
			data={data}
		>
			<Icon icon={faLinkedinIn} />
		</LinkParser>
	);
};

LinkedInLink.defaultProps = {
	className: '',
};

export default LinkedInLink;
