import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';

export default class Column extends ContentItemWithResolver {
	get headerText() {
		return get(this, '_header_text.value', '');
	}

	get headerImage() {
		return get(this, '_header_image.assets[0]', null);
	}

	get text() {
		return get(this, '_text.value', '');
	}
}
