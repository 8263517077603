import React from 'react';
import { observer } from 'mobx-react';
import isEmpty from 'lodash/isEmpty';
import { SlideWithIcon as SlideModel } from 'models';
import Button from '../Buttons/Button/Button';
import BackButton from 'components/Buttons/Arrow/BackButton';
import NextButton from 'components/Buttons/Arrow/NextButton';
import styles from './Slide.module.scss';

export interface SlideProps {
  data: SlideModel;
  className?: string;
  setSlide?: any;
  changeSlide?: any;
  slideNumber: number;
  currentSlideIndex: number;
}

const Slide = observer((props: SlideProps) => {
  const {
    data: {
      subTitle,
      description,
      link,
    },
    className,
    slideNumber,
    currentSlideIndex,
  } = props;

  return (
    <div className={`${className} ${styles.Slide}`}>
      <div className={styles.Wrapper}>
        <BackButton
          onClick={() => {
            props.changeSlide(currentSlideIndex -1)
          }}
          className={styles.ButtonWrapper}
          arrowClassName={`${styles.Button} ${styles.LeftButton}`}
        />
      <div className={styles.Square} />
      <div className={`${styles.Square} ${styles.CenteredSquare}`}>
        <div className={styles.SlideNumber}>
          {slideNumber}
        </div>
      </div>
      <div className={styles.Square} />
        <NextButton
          onClick={() => props.changeSlide(currentSlideIndex + 1)}
          className={styles.ButtonWrapper}
          arrowClassName={`${styles.Button} ${styles.RightButton}`}
        />
      </div>
      <div className={styles.Subtitle}>{subTitle}</div>
      {description ?  <div className={styles.Description}>{description}</div> : null}
      {!isEmpty(link) && (
        <Button className={styles.ButtonLink}>
          {link.text}
        </Button>
      )}
    </div>
  );
});

export default Slide;
