import get from 'lodash/get';
import ContentItemWithResolver from "models/ContentItemWithResolver";

export default class DownloadLink extends ContentItemWithResolver {
	get text(): string {
		return get(this, '_text.value', '');
	}

	get file(): string {
		return get(this, '_file.assets[0].url', '');
	}

	get fileType(): string {
		return get(this, '_file.value[0].type', '');
	}

	get fileName(): string {
		return get(this, '_file.value[0].name', '');
	}
}
