import React, { FunctionComponent} from 'react';
import { KENTICO_TYPES } from 'common/consts/kentico';
import InnerLink from 'blocks/InnerLink/InnerLink';
import OuterLink from 'blocks/OuterLink/OuterLink';
import DownloadLink from 'blocks/DownloadLink/DownloadLink';

export interface LinkParserProps extends React.PropsWithChildren<{}> {
	data: any;
	className?: string;
}

const LinkParser: FunctionComponent<LinkParserProps> = ({ data, className, children }) => {
	if (!data || !data.system) {
		return null;
	}
	switch (data.system.type) {
		case KENTICO_TYPES.INNER_LINK:
		return (
			<InnerLink
				data={data}
				className={className}
				children={children}
			/>
		);
		case KENTICO_TYPES.OUTER_LINK:
		return (
			<OuterLink
				data={data}
				className={className}
				children={children}
			/>
		);
		case KENTICO_TYPES.DOWNLOAD_LINK:
		return (
			<DownloadLink
				data={data}
				className={className}
				children={children}
			/>
		);
	}
	return null;
};

export default LinkParser;
