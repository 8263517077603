import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Bind } from 'lodash-decorators';
import NavigatorStore from "stores/navigator.store";
import styles from './SectionsFilter.module.scss';
import CaseStudiesStore from "../../../stores/caseStudies.store";
import { setOptionsList } from "./utils";
import MultiSelect from '../../../components/MultiSelect/MultiSelect';
import { FILTER_TYPE } from 'common/consts/common';


export interface CaseStudiesListingProps {
	caseStudiesStore: CaseStudiesStore;
	navigatorStore: NavigatorStore;
	noValueText: string;
	noValueLawText: string;
}

@inject('caseStudiesStore', 'windowStore', 'navigatorStore')
@observer
class SectionsFilter extends Component<CaseStudiesListingProps> {
	@Bind
	onSectionClick(type, event: any, option: string) {
		this.props.caseStudiesStore.setFilter(option, type);
	}

	render() {
		const {
			caseStudiesStore: {
				caseStudiesSections,
				caseStudiesLaw
			},
			noValueText,
			noValueLawText
		} = this.props;

		return (
			<div className={styles.Container}>
				<div className={styles.Wrapper}>
					<MultiSelect
						placeholder={noValueText}
						options={setOptionsList(caseStudiesSections)}
						onChange={(event: React.ChangeEvent<HTMLInputElement>, option: string) =>
							 this.onSectionClick(FILTER_TYPE.SECTION_CATEGORY, event, option)}
					/>
					<MultiSelect
						placeholder={noValueLawText}
						options={setOptionsList(caseStudiesLaw)}
						onChange={(event: React.ChangeEvent<HTMLInputElement>, option: string) =>
							 this.onSectionClick(FILTER_TYPE.LAW_CATEGORY, event, option)}
					/>
				</div>
			</div>
		);
	}
}

export default SectionsFilter as any;
