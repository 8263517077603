import { observable, action, when, computed, reaction } from 'mobx';
import CoreStore from 'stores/core.store';
import CaseStudiesService from 'services/caseStudies.service';
import { KENTICO_TAXONOMY } from 'common/consts/kentico';
import { DESKTOP_ARTICLES_PER_PAGE, MOBILE_ARTICLES_PER_PAGE } from "common/consts/caseStudies";
import { slashesToDashes } from "common/methods/string";
import { FILTER_TYPE } from 'common/consts/common';

class CaseStudiesStore {
	@observable loadedArticles: Map<string, any> = new Map();

	@observable page = 1;

	@observable filterCategories: string[] = [];

	@observable filterLawCategories: string[] = [];

	@observable caseStudiesListLoaded = false;

	@observable caseStudiesList: any[] = [];

	@observable caseStudiesSections: Map<string, string> = new Map(); // section_taxonomy_codename -> section translated name

	@observable caseStudiesLaw: Map<string, string> = new Map(); // section_taxonomy_codename -> section translated name

	@observable isCaseStudyLoading = false;

	@observable currentCaseStudy: any | null = null;

	@observable caseStudiesPerPage = DESKTOP_ARTICLES_PER_PAGE;

	constructor(private coreStore: CoreStore) {
		when(() => coreStore.initialCoreDataFetchDone)
			.then(() => {
				this.translateCaseStudiesSections();
				this.translateCaseStudiesLaw();
			});
		this.caseStudiesPerPage = coreStore.windowStore.mediaSmallDesktop ? DESKTOP_ARTICLES_PER_PAGE : MOBILE_ARTICLES_PER_PAGE;
		reaction(() => coreStore.windowStore.mediaSmallDesktop, (isDesktop) => {
			const pagesNormal = this.page / this.pagesCount;
			this.caseStudiesPerPage = isDesktop ? DESKTOP_ARTICLES_PER_PAGE : MOBILE_ARTICLES_PER_PAGE;
			this.page = Math.floor(this.pagesCount * pagesNormal);
		});
	}

	@computed
	get filteredArticleList() {
		const allFilters = [...this.filterCategories, ...this.filterLawCategories];

		if (allFilters.length === 0) {
			return this.caseStudiesList;
		}
		return this.caseStudiesList.filter((article) => {
			const articleCategories = [...article.categories, ...article.lawCategories];
			return articleCategories.some((category) => allFilters.some((codename) => codename === category.codename));
		});
	}

	@computed
	get pagedArticleList() {
		return this.filteredArticleList.slice((this.page - 1) * this.caseStudiesPerPage, (this.page) * this.caseStudiesPerPage);
	}

	@computed
	get pagesCount() {
		return Math.ceil(this.filteredArticleList.length / this.caseStudiesPerPage);
	}

	@action.bound
	private translateCaseStudiesSections() {
		const { taxonomies, translations } = this.coreStore;

		const tax = taxonomies.get(KENTICO_TAXONOMY.CASE_STUDIES_SECTIONS);

		if (!tax) {
			return;
		}

		tax.forEach((sectionTaxonomy) => {
			const sectionTranslation = translations && Array.isArray(translations.caseStudiesSections)
				&& translations.caseStudiesSections.find(
					(st) => {
						return st.caseStudiesSectionCodeName === sectionTaxonomy.codename
					});
			const translation = sectionTranslation ? sectionTranslation.translation : sectionTaxonomy.name;
			this.caseStudiesSections.set(sectionTaxonomy.codename, translation);
		});

	}

	@action.bound
	private translateCaseStudiesLaw() {
		const { taxonomies, translations } = this.coreStore;

		const tax = taxonomies.get(KENTICO_TAXONOMY.CASE_STUDIES_LAW);
		if (!tax) {
			return;
		}


		tax.forEach((lawTaxonomy) => {
			const lawTranslations = translations && Array.isArray(translations.caseStudiesLaw)
				&& translations.caseStudiesLaw.find(
					(st) => {
						return st.caseStudiesLawCodeName === lawTaxonomy.codename
					});
			const translation = lawTranslations ? lawTranslations.translation : lawTaxonomy.name;
			this.caseStudiesLaw.set(lawTaxonomy.codename, translation);
		});

	}

	@action.bound
	public setPage(pageNumber: number) {
		this.page = pageNumber;
	}

	@action.bound
	setFilter(filter: string, type: string) {
		if (type === FILTER_TYPE.SECTION_CATEGORY) {
			return this.changeFilters(this.filterCategories, filter)
		}
		return this.changeFilters(this.filterLawCategories, filter);
	}

	@action.bound
	changeFilters(filtersCategory, filter: string) {
		const filtersArr = [...filtersCategory];

		if (filtersArr.includes(filter)) {
			filtersCategory.remove(filter);
			this.setPage(1);
		} else {
			filtersCategory.push(filter);
			this.setPage(1);
		}
	}

	@action.bound
	clearFilter() {
		this.filterCategories = [];
		this.filterLawCategories = [];
	}

	@action.bound
	async getArticleList() {
		if (!this.caseStudiesListLoaded) {
			try {
				this.caseStudiesList = await CaseStudiesService.fetchArticleList();
				this.caseStudiesListLoaded = true;
			} catch (e) {
				console.error(`Failed to fetch article list: `, e);
			}
		}
	}

	@action.bound
	async getArticle(slug: string) {
		if (!slug) {
			return;
		}
		let article = this.loadedArticles.get(slug);

		if (!article) {
			this.setArticleLoading(true);
			try {
				article = await CaseStudiesService.fetchArticle(slug);
				if (article) {
					this.loadedArticles.set(slug, article);
				}
			} catch (e) {
				console.error(`Failed to fetch the article by slug ${slug}: `, e);
			}
			this.setArticleLoading(false);
		}

		if (article) {
			this.coreStore.setContainerFound(true);
			this.currentCaseStudy = article;
		} else {
			this.coreStore.setContainerFound(false);
		}

	}

	validateCategory(category: string) {
		if (this.caseStudiesSections.has(slashesToDashes(category)) || category === '') {
			this.coreStore.setContainerFound(true);
		} else {
			this.coreStore.setContainerFound(false);
			this.coreStore.viewStore.currentView = null;
		}
	}

	@action.bound
	setArticleLoading(isCaseStudyLoading: boolean) {
		this.isCaseStudyLoading = isCaseStudyLoading;
	}

}

export default CaseStudiesStore;
