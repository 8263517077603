import get from 'lodash/get';
import ContentItemWithResolver from "models/ContentItemWithResolver";
import BlogSectionTranslation from './BlogSectionTranslation';
import CaseStudiesSectionTranslation from "./CaseStudiesSectionTranslation";
import CaseStudiesLawTranslation from "./CaseStudiesLawTranslation";
import GlobalTranslation from "./GlobalTranslation";

export default class Translations extends ContentItemWithResolver {
	get blogSections(): BlogSectionTranslation[] {
		return get(this, '_blog_sections', []);
	}
	get caseStudiesSections(): CaseStudiesSectionTranslation[] {
		return get(this, '_case_studies_sections', []);
	}
	get caseStudiesLaw(): CaseStudiesLawTranslation[] {
		return get(this, '_case_studies_law', []);
	}

	get global(): GlobalTranslation[] {
		return get(this, '_global', []);
	}
}
