import get from 'lodash/get';
import ContentItemWithResolver from "models/ContentItemWithResolver";

export default class PhoneLink extends ContentItemWithResolver {
	get linkText() {
		return get(this, '_link_text.value', '');
	}

	get phoneNumber() {
		return get(this, '_phone_number.value', '');
	}
}
