import React, { FunctionComponent} from 'react';
import { Markup } from 'components/Markup/Markup';
import {RichTextWithTitle as RichTextWithTitleModel} from "models";
import {NBSPAfterSingleLetters} from "common/methods/string";
import Layout from "components/Layout/Layout";
import ArticleRichTextParser from 'components/RichTextParser/ArticleRichTextParser';
import styles from './RichTextWithTitle.module.scss';

export interface RichTextWithTitleProps {
	data: RichTextWithTitleModel;
}

const RichTextWithTitle: FunctionComponent<RichTextWithTitleProps> = ({ data }) => (
		<Layout>
			<article className={styles.Container}>
				<h1 className={styles.Title}>
					<Markup
						wrap={false}
						markup={NBSPAfterSingleLetters(data.title)}
					/>
				</h1>
				<ArticleRichTextParser
					kenticoContent={data.content}
				/>
			</article>
		</Layout>
	);

export default RichTextWithTitle;
