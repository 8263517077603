import { DropDownValue } from "models/DropDownValue";

export const translatedValueList = (allTags: Map<string, string>, tags) => {
  const allTagsArr = Array.from(allTags)
  .map((sectionEntry) => new DropDownValue(sectionEntry[0], sectionEntry[1]));

  const tagsCodenames = tags.map(tag => tag.codename);
  const translatedTags = allTagsArr.filter(tag => tagsCodenames.indexOf(tag.value) > -1 );

  return translatedTags;
}
