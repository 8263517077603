export const LANGUAGE_STORAGE_KEY = 'legalhut-language';

const LANGUAGES = new Map();

LANGUAGES.set('PL', 'pl');
LANGUAGES.set('EN', 'en');

export const POLISH = 'Polski';
export const ENGLISH = 'English';
export const ANCHOR_PL = 'pl/';
export const ANCHOR_EN = 'en/';
export const DEFAULT_LANGUAGE_ANCHOR = '/pl';

export const isLanguageValue = value => Array.from(LANGUAGES.values()).some(lang => lang === value);

export default LANGUAGES;

