import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import root from 'window-or-global';
import { observable, reaction } from 'mobx';
import { isVideo } from 'common/methods/string';
import Bind from 'lodash-decorators/bind';
import { FieldModels } from 'kentico-cloud-delivery';
import ResponsiveImage from 'components/Asset/ResponsiveImage/ResponsiveImage';
import styles from './Asset.module.scss';

type AssetModel = FieldModels.AssetModel;

export interface AssetProps {
	data: AssetModel;
	ref?: (r) => any;
	renderImmediately?: boolean;
	windowStore?: any;
	withPlaceholder?: boolean;
	imgConfig?: any;
	videoConfig?: any;
	className?: string;
	classNameIE?: string;
	assetClassName?: string;
	onClick?: () => any;
}

@inject('windowStore')
@observer
class Asset extends Component<AssetProps> {
	@observable shouldRender = false;

	assetRef;
	placeHolderRef;
	containerRef;
	disposeScrollYReaction;

	componentDidMount() {
		const {
			ref,
			windowStore,
			renderImmediately,
			withPlaceholder,
		} = this.props;

		if (ref) {
			ref(this.containerRef);
		}

		if (withPlaceholder) {
			this.showPlaceholder();
		}

		// determine if should render image:
		if (!renderImmediately) {
			this.shouldRender = this.determineIfShouldRender();
			// lazy loading on scroll:
			if (!this.shouldRender) {
				this.disposeScrollYReaction = reaction(() => windowStore.scrollY, () => {
					this.shouldRender = this.determineIfShouldRender();
					if (this.shouldRender) {
						this.disposeScrollYReaction();
					}
				}, { delay: 250 });
			}
		}
	}

	componentWillUnmount() {
		if (this.disposeScrollYReaction) {
			this.disposeScrollYReaction();
		}
	}

	@Bind
	showPlaceholder() {
		if (this.assetRef) {
			this.assetRef.style.opacity = 0;
		}
		if (this.placeHolderRef) {
			this.placeHolderRef.style.opacity = 1;
		}
	}

	@Bind
	hidePlaceholder() {
		if (this.assetRef) {
			this.assetRef.style.opacity = 1;
		}
		if (this.placeHolderRef) {
			this.placeHolderRef.style.opacity = 0;
		}
	}

	determineIfShouldRender() {
		const {
			windowStore,
		} = this.props;
		const screenHeight = (root.innerHeight || root.clientHeight);
		const renderMargin = screenHeight * (windowStore.mediaDesktop ? 1.75 : 2.25);
		const assetTop = this.containerRef && this.containerRef.getBoundingClientRect().top;
		return windowStore.scrollY > (assetTop - renderMargin);
	}

	render() {
		const {
			data,
			imgConfig,
			videoConfig,
			className,
			classNameIE,
			assetClassName,
			withPlaceholder,
			onClick,
		} = this.props;

		if (!data) {
			return null;
		}

		return (
			<div
				ref={(ref) => this.containerRef = ref}
				className={`${className || styles.RelativeContainer}`}
			>
				{ withPlaceholder && (
					<div
						ref={(ref) => this.placeHolderRef = ref}
						className={`${styles.placeHolder} ${assetClassName}`}
					/>
				)}

				{this.shouldRender && (
					isVideo(data.name) ? (
						<video
							ref={(ref) => this.assetRef = ref}
							className={assetClassName || styles.Video}
							src={data.url}
							alt={data.description}
							{...videoConfig}
							muted={videoConfig.muted || true}
							autoPlay={videoConfig.autoPlay || true}
							loop={videoConfig.loop || true}
							playsInline={videoConfig.playsInline || true}
							width={videoConfig.width || "100%"}
							height={videoConfig.height || "auto"}
							onLoadedData={this.hidePlaceholder}
							onError={this.hidePlaceholder}
							onClick={onClick}
						/>
					) : (
						<ResponsiveImage
							innerRef={(ref) => this.assetRef = ref}
							className={assetClassName || styles.Image}
							classNameIE={classNameIE}
							src={data.url}
							alt={data.description}
							imgConfig={imgConfig}
							onLoad={this.hidePlaceholder}
							onError={this.hidePlaceholder}
							onClick={onClick}
						/>
					)
				)}
			</div>
		);
	}
}

export default Asset;
