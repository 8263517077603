import React, { FunctionComponent } from 'react';
import { Markup } from 'components/Markup/Markup';
import { NBSPAfterSingleLetters } from 'common/methods/string';
import styles from './RichTextParser.module.scss';

export interface RichTextParserProps {
	content?: string;
	className?: string;
	kenticoContent?: any;
}

const RichTextParser: FunctionComponent<RichTextParserProps> = ({ content, kenticoContent, className }) => {
	const contentString = NBSPAfterSingleLetters((kenticoContent && kenticoContent.resolveHtml()) || content);
	return contentString ? (
		<Markup
			className={className || styles.RichTextParser}
			markup={contentString}
			type="html"
		/>
	) : null;
};

export default RichTextParser;
