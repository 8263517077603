import get from 'lodash/get';
import ContentItemWithColorPickers from './ContentItemWithColorPickers';

export default class EmployeeContactSection extends ContentItemWithColorPickers {


	get bigHeader() {
		return get(this, '_big_header.value', '');
	}

	get employeeImage() {
		return get(this, '_employee_image.assets[0]', null);
	}

	get name() {
		return get(this, '_name.value', '');
	}

	get position() {
		return get(this, '_position.value', '');
	}

	get telephone() {
		return get(this, '_telephone.value', '');
	}

	get email() {
		return get(this, '_email.value', '');
	}
}
