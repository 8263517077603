import React, {  FunctionComponent } from "react";
import { MultipleColumnsWithHeaderSection as MultipleColumnsWithHeaderSectionModel } from "models";
import Layout from "components/Layout/Layout";
import Column from "components/Column/Column";
import Background from "components/Background/Background";
import { Markup } from "components/Markup/Markup";
import { NBSPAfterSingleLetters } from "common/methods/string";
import styles from "./MultipleColumnsWithHeaderSection.module.scss";
import Button from "components/Buttons/Button/Button";
import LinkParser from "components/LinkParser/LinkParser";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import {isMobile} from "react-device-detect";

export interface MultipleColumnsWithHeaderSectionProps {
  data: MultipleColumnsWithHeaderSectionModel;
}

const MultipleColumnsSectionWithHeader: FunctionComponent<MultipleColumnsWithHeaderSectionProps> = ({
  data
}) => {
  const {
    bigHeading,
    heading,
    subHeading,
    columns,
    textColor,
    backgroundColor,
    button
  } = data;

  return (
    <Background
      data={{ backgroundColor, textColor }}
      className={styles.Container}
    >
      <Layout>
        <div className={styles.TitleWithButtonWrapper}>
          {bigHeading ? (
              <h2 className={`${textColor==='secondary__default_text_color_'? styles.PrimaryColor : ""} ${styles.BigHeading}`}>{bigHeading}</h2>
          ) : null}
          {!isMobile ? (
              button ?
                    <LinkParser data={button} className={styles.TitleWithButtonWrapper__ButtonPosition}>
                      <Button className={`${backgroundColor==='primary'? styles.Button : ""}`}>{button.text} </Button>
                    </LinkParser>
                 : null)
           : null}
        </div>
        <h3 className={styles.Heading}>
          <Markup markup={NBSPAfterSingleLetters(heading)} type="html" />
        </h3>
        {subHeading && <h4 className={styles.SubHeading}>
          <Markup markup={NBSPAfterSingleLetters(subHeading)} type="html" />
        </h4>}
        <div className={styles.ColumnsContainer}>
          {columns.map(column => (
            <Column
              className={styles.Column}
              key={column.system.id}
              data={column}
            />
          ))}
        </div>
        {isMobile ?
            (<div className={styles.BottomContactButtonSection}>
              { button ? (
                  <LinkParser data={button} className={styles.BottomContactButtonSection__ButtonPosition}>
                    <Button className={`${backgroundColor==='primary'? styles.Button : ""}`}>{button.text} </Button>
                  </LinkParser>
              ) : null
              }
            </div>) : null}
      </Layout>
    </Background>
  );
};

export default MultipleColumnsSectionWithHeader;
