import get from 'lodash/get';
import ContentItemWithResolver from "models/ContentItemWithResolver";

export default class CtaContactSection extends ContentItemWithResolver {
  get text(): string {
		return get(this, '_text.value', '');
  }

  get header(): string {
		return get(this, '_header.value', '');
  }
  
  get buttonText(): string {
		return get(this, '_button_text.value', '');
  }
  
  get buttonLink(): any {
		return get(this, '_button_link[0]', {});
	}
}
