import React, { Component } from 'react';
import { TwoColumnsWithHeaderSection as TwoColumnsWithHeaderSectionModel } from 'models';
import Layout from 'components/Layout/Layout';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import Background from 'components/Background/Background';
import ColumnItem from './ColumnItem/ColumnItem';
import styles from './TwoTextColumnsWithHeaderSection.module.scss';
import LinkParser from "../../components/LinkParser/LinkParser";
import Button from "../../components/Buttons/Button/Button";
import {isMobile} from "react-device-detect";

export interface ITwoColumnsWithHeaderSection {
	data: TwoColumnsWithHeaderSectionModel;
}

class TwoTextColumns extends Component<ITwoColumnsWithHeaderSection> {

	render() {
		const {
			data: {
				headingText,
				columns,
				backgroundAsset,
				textColor,
				backgroundColor,
				contactButtonLink
			},
		} = this.props;

		return (
			<Background
				className={styles.MainContainer}
				data={{backgroundColor, textColor}}
				backgroundImage={`url(${backgroundAsset.url})`}
			>
				<Layout>
				<div className={styles.Container}>
					<div className={styles.TitleWithButtonWrapper}>
						<SectionTitle title={headingText} className={styles.MainHeading} />
						{!isMobile ? (
							contactButtonLink ? (
								<LinkParser
									data={contactButtonLink}
									className={styles.TitleWithButtonWrapper__ButtonPosition}
								>
									<Button type="button" className={`${backgroundColor==='primary'? styles.Button : ""}`}>
										{contactButtonLink.text}
									</Button>
								</LinkParser>
							) : null
						) : null }
					</div>
						{columns.map((column, index) => (
							<ColumnItem key={column.system.id} column={column} textColor={textColor} />
					))}
				</div>
				{isMobile ?
					(<div className={styles.BottomContactButtonSection}>
					{ contactButtonLink ? (
						<LinkParser
							data={contactButtonLink}
							className={styles.BottomContactButtonSection__ButtonPosition}
						>
							<Button type="button" className={`${backgroundColor==='primary'? styles.Button : ""}`}>
								{contactButtonLink.text}
							</Button>
						</LinkParser>
					) : null
					}
				</div>) : null}
			</Layout>
			</Background>
		);
	}
}

export default TwoTextColumns;
