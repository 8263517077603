import get from 'lodash/get';
import ContentItemWithResolver from "models/ContentItemWithResolver";
import PromoSection from "models/PromoSection";

export default class BlogListing extends ContentItemWithResolver {
	get blogHeader() {
		return get(this, '_blog_header.value', '');
	}

	get blogSubHeader() {
		return get(this, '_blog_subheader.value', '');
	}

	get promoSection(): PromoSection {
		return get(this, '_promo_section[0]', null);
	}

	get filterSelectAllText(): string {
		return get(this, '_filter_select_all_text.value', '');
	}
}
