import get from 'lodash/get';
import ContentItemWithResolver from "models/ContentItemWithResolver";

export default class VoiceRecorder extends ContentItemWithResolver {
	get textBeforeButton(): string {
		return get(this, '_text_before_button.value', '');
	}

	get textAfterButton(): string {
		return get(this, '_text_after_button.value', '');
	}

	get recordButtonText(): string {
		return get(this, '_record_button_text.value', '');
	}

	get recordNewButtonText(): string {
		return get(this, '_record_new_button_text.value', '');
	}

	get stopRecordingButtonText(): string {
		return get(this, '_stop_recording_button_text.value', '');
	}
}
