import ContentItemWithResolver from "models/ContentItemWithResolver";
import get from 'lodash/get';

export default class SliderWithIcon extends ContentItemWithResolver {

  get header() {
    return get(this, '_big_header.value', '');
  }

  get description() {
    return get(this, '_header.value', '');//because it previously was header
  }

  get sliders() {
    return get(this, '_sliders', []);
  }

  get buttons() {
    return get(this, '_buttons[0]', []);
  }

  get contactButtonLink() {
    return get(this, '_contact_button_link[0]', {});
  }
}
