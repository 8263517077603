import React, { FunctionComponent } from 'react';
import styles from './Button.module.scss';

export interface ButtonProps {
  className?: string;
  onClick?: () => void;
  children?: string;
}

const Button: FunctionComponent<ButtonProps> = ({ onClick, children, className }) => (
  <button
    type="submit"
    className={`${styles.Button} ${className}`}
    onClick={onClick}
  >
    {children}
  </button>
);

export default Button;
