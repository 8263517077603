import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import BlogArticle from "models/BlogArticle";
import { Tag } from 'components/Tag/Tag';
import { formatArticleDate } from 'common/methods/date';
import { Bind } from 'lodash-decorators';
import NavigatorStore from 'stores/navigator.store';
import ANCHOR from 'common/consts/anchor';
import {NBSPAfterSingleLetters, underscoresToDashes} from 'common/methods/string';
import { Markup } from 'components/Markup/Markup';
import styles from './ArticleHeader.module.scss';

export interface ArticleContentProps {
	navigatorStore: NavigatorStore;
	className?: string;
	data: BlogArticle;
}

@inject('navigatorStore')
@observer
class ArticleHeader extends Component<ArticleContentProps> {

	static defaultProps = {
		className: '',
	};

	@Bind
	onTagClick(sectionCodeName: string) {
		const {
			navigatorStore,
		} = this.props;
		const category = underscoresToDashes(sectionCodeName);

		navigatorStore.navigate(`${ANCHOR.BLOG}/${category}`);
	}

	render() {
		const {
			data,
			className,
		} = this.props;

		return (
			<header className={`${className} ${styles.Header}`}>
				{data.categories.map((category) => (
					<span onClick={() => this.onTagClick(category.codename)}>
						<Tag className={styles.Tag}>
							{category.name}
						</Tag>
					</span>
				))}
				<time className={styles.Date}>
					{formatArticleDate(data.publicationDate)}
				</time>
				<h1 className={styles.Title}>
					<Markup
							wrap={false}
							markup={NBSPAfterSingleLetters(data.title)}
					/>
				</h1>
			</header>
		);
	}
}

export default ArticleHeader as any;
