import get from 'lodash/get';
import ContentItemWithColorPickers from 'models/ContentItemWithColorPickers';
import { FieldModels } from 'kentico-cloud-delivery';

type AssetModel = FieldModels.AssetModel;

export default class Slide extends ContentItemWithColorPickers {
	get linkedInLink() {
		return get(this, '_linkedin_link[0]', null);
	}

	get name() {
		return get(this, '_name.value', '');
	}

	get position() {
		return get(this, '_position.value', '');
	}

	get description() {
		return get(this, '_description.value', '');
	}

	get backgroundImage(): AssetModel {
		return get(this, '_background_image.assets[0]', null);
	}
}
