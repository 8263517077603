import React, {  Component } from 'react';
import { inject, observer } from 'mobx-react';
import { underscoresToDashes } from "common/methods/string";
import BlogStore from "stores/blog.store";
import HorizontalLine from "components/HorizontalLine/HorizontalLine";
import { Bind } from 'lodash-decorators';
import DropDown from "components/DropDown/DropDown";
import { DropDownValue } from "models/DropDownValue";
import WindowStore from "stores/window.store";
import NavigatorStore from "stores/navigator.store";
import styles from './SectionsFilter.module.scss';

export interface BlogListingProps {
	blogStore: BlogStore;
	windowStore: WindowStore;
	navigatorStore: NavigatorStore;
	noValueText: string;
}

@inject('blogStore', 'windowStore', 'navigatorStore')
@observer
class SectionsFilter extends Component<BlogListingProps> {

	@Bind
	onSectionClick(sectionCodeName: string) {
		const { navigatorStore } = this.props;

		navigatorStore.navigate(`/blog/${underscoresToDashes(sectionCodeName)}`);
	}

	render() {
		const {
			blogStore: {
				filter,
				blogSections,
			},
			windowStore: {
				mediaLargeDesktop,
			},
			noValueText,
		} = this.props;

		const valueList = Array.from(blogSections)
			.map((sectionEntry) => new DropDownValue(sectionEntry[0], sectionEntry[1]));
		valueList.unshift(new DropDownValue('', noValueText));

		return (
			<div className={styles.Container}>
				<HorizontalLine
					className={styles.HorizontalLine}
				/>
					{!mediaLargeDesktop && (
						<DropDown
							className={styles.DropDown}
							value={filter[0]}
							valueList={valueList}
							onSelect={this.onSectionClick}
						/>
					)}
					{mediaLargeDesktop && (
						<ul className={styles.Sections}>
							<li
								className={`${styles.Section} ${filter.length === 0 && styles.Active}`}
								onClick={() => this.onSectionClick('')}
							>
								{noValueText}
							</li>
							{Array.from(blogSections).map((sectionEntry) =>
								(
									<li
										className={`${styles.Section} ${filter.some((f) => f === sectionEntry[0]) && styles.Active}`}
										onClick={() => this.onSectionClick(sectionEntry[0])}
									>
										{sectionEntry[1]}
									</li>
								))
							}
						</ul>
					)}
				<HorizontalLine
					className={styles.HorizontalLine}
				/>
			</div>
		);
	}
}

export default SectionsFilter as any;
