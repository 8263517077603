export const PROJECT_ID = process.env.REACT_APP_KENTICO_PROJECT_ID || '';
export const PREVIEW_API_KEY = process.env.REACT_APP_KENTICO_PREVIEW_API_KEY || '';

export const KENTICO_PROXY_BASE_URL = process.env.REACT_APP_KENTICO_PROXY_BASE_URL || '';
export const KENTICO_ID_LENGTH = 36;

export enum KENTICO_TAXONOMY {
	BLOG_SECTIONS = 'blog_sections',
	CASE_STUDIES_SECTIONS = 'case_studies_sections',
	CASE_STUDIES_LAW = 'case_studies_law',
	SLUG = 'slug',
}

export const KENTICO_TYPES = {
	SEO: 'seo',
	INNER_LINK: 'inner_link',
	OUTER_LINK: 'outerlink',
	DOWNLOAD_LINK: 'download_link',
	PROMO_SECTION: 'promo_section',
	VIEW: 'view',
	CASE_STUDY_ARTICLE: 'case_study_article',
	BLOG_ARTICLE: 'blog_article',
	CORE: 'core',
	CONTACT_FORM_SECTION: 'contact_form_section',
	PAGE_NOT_FOUND: 'page_not_found',
	COLUMN: 'column',
	TWO_COLUMNS_WITH_HEADER_SECTION: 'two_columns_with_header_section',
	THREE_COLUMNS_WITH_HEADER_SECTION: 'three_columns_with_header_section',
	MULTIPLE_COLUMNS_WITH_HEADER_SECTION: 'multiple_columns_section_with_header',
	FOUR_COLUMNS_WITH_HEADER_SECTION: 'four_columns_section_with_header',
	MULTIPLE_COLUMNS_SECTION: 'multiple_columns_section',
	SLIDER_SECTION: 'slider_section',
	SLIDER_SECTION_WITH_HEADER: 'slider_section_with_header',
	SLIDE: 'slide',
	HERO_SECTION: 'hero_section',
	HERO_HEADING_TEXT: 'hero_heading_text',
	NAVIGATOR: 'navigator',
	FOOTER_SECTION: 'footer_section',
	NAVBAR: 'navbar',
	VOICE_RECORDER: 'voicerecorder',
	PHONE_LINK: 'phonelink',
	COLLABORATIONS_COLUMNS: 'collaborations_columns',
	TESTIMONIALS: 'testimonials',
	PARTNERS_SECTION: 'partners_section',
	BLOG_LISTING: 'blog_listing',
	CASE_STUDIES_LISTING: 'case_studies_listing',
	TRANSLATIONS: 'translations',
	GLOBAL_TRANSLATIONS: 'global_translations',
	BLOG_SECTION_TRANSLATION: 'blog_section_translation',
	CASE_STUDIES_SECTION_TRANSLATION: 'case_studies_section_translation',
	CASE_STUDIES_LAW_TRANSLATION: 'case_studies_law_translation',
	BLOCK_QUOTE: 'block_quote',
	BLOG_AUTHOR: 'blog_author',
	NEWSLETTER: 'newsletter',
	RICH_TEXT_WITH_TITLE: 'rich_text_with_title',
	SLIDER_BUTTON: 'slider_button',
	SLIDER_BUTTONS_SECTION: 'slider_buttons_section',
	SLIDE_WITH_ICON: 'slide_with_icon',
	SLIDER_WITH_ICON: 'slider_with_icon',
	EMPLOYEE_CONTACT_SECTION: 'employee_contact_section',
	CTA_CONTACT_SECTION: 'cta_contact_section',
	LINKED_IMAGE: 'linked_image',
};
