import React, { Component } from "react";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { Bind } from "lodash-decorators";
import CoreStore from "stores/core.store";
import WindowStore from "stores/window.store";
import Asset from "components/Asset/Asset";
import LinkParser from "components/LinkParser/LinkParser";
import LinkedInLink from "components/SocialLinks/LinkedInLink";
import YoutubeLink from "components/SocialLinks/YoutubeLink";
import FacebookLink from "components/SocialLinks/FacebookLink";
import Button from "components/Buttons/Button/Button";
import Languages from "components/Languages/Languages";
import LanguageService from "services/language.service";
import HorizontalLine from "components/HorizontalLine/HorizontalLine";
import OuterLink from "models/OuterLink";
import ANCHOR from "common/consts/anchor";
import NavigatorStore from "stores/navigator.store";
import styles from "./MobileNav.module.scss";

export interface MobileNavProps {
  contactButtonLink: any;
  links: any[];
  linkedInLink: OuterLink;
  youTubeLink: OuterLink;
  facebookLink: OuterLink;
  coreStore: CoreStore;
  navigatorStore: NavigatorStore;
  windowStore: WindowStore;
  className: string;
}

@inject("coreStore", "navigatorStore", "windowStore")
@observer
class MobileNav extends Component<MobileNavProps> {
  @observable isOpened = false;

  navRef: HTMLElement | null = null;

  componentDidMount() {
    window.addEventListener("mousedown", this.onOutsideClick, false);
  }

  componentWillUnmount() {
    window.removeEventListener("mousedown", this.onOutsideClick, false);
  }

  @Bind
  @action
  toggleMenu() {
    this.isOpened = !this.isOpened;
  }

  @Bind
  @action
  onOutsideClick(e) {
    if (this.navRef?.contains(e.target)) {
      return;
    }
    this.isOpened = false;
  }

  @Bind
  @action
  onItemClick() {
    this.isOpened = false;
  }

  @Bind
  navigateHome() {
    const { navigatorStore } = this.props;
    navigatorStore.navigate(ANCHOR.HOME);
    this.onItemClick();
  }

  render() {
    const {
      coreStore: { data },
      contactButtonLink,
      links,
      linkedInLink,
      youTubeLink,
      facebookLink,
      className,
    } = this.props;

    return (
      <div
        ref={(navRef) => (this.navRef = navRef)}
        className={`${styles.Navbar} ${className}`}
      >
        <div className={styles.WhiteBackgroundShow} />
        <div className={styles.CollapsedMenuContainer}>
          <Asset
            data={data.smallLogo || data.logo}
            className={styles.LogoContainer}
            assetClassName={styles.Logo}
            onClick={this.navigateHome}
          />
          <div
            className={`
						${styles.CollapsedMenuBurger}
						${this.isOpened ? styles.BurgerOpen : ""}`}
            onClick={this.toggleMenu}
          />
        </div>
        <div
          className={`${styles.MobileNavBackground} ${
            this.isOpened ? styles.MobileNavShow : styles.MobileNavHide
          }`}
        >
          <div className={styles.MenuContainer}>
            <ul className={styles.MainLinksContainer}>
              {links.map((link) => (
                <li
                  className={styles.MainLink}
                  onClick={this.onItemClick}
                  key={link.text}
                >
                  <LinkParser data={link}>{link.text}</LinkParser>
                </li>
              ))}
            </ul>
            <div className={styles.NavButtons}>
              {/* uncomment when languages added {!LanguageService.isBlogPathName() && <Languages
								language={LanguageService.getCurrentLanguage()}
								setLanguage={() => LanguageService.setCurrentLanguage()}
								isMobile
							/>} */}
              <LinkParser
                data={contactButtonLink}
                className={styles.ContactNavButton}
              >
                <Button type="button" onClick={this.onItemClick}>
                  {contactButtonLink.text}
                </Button>
              </LinkParser>
              <HorizontalLine className={styles.MobileNavHorizontalLine} />
              <div className={styles.SocialIcons}>
                {linkedInLink.link && (
                  <LinkedInLink className={styles.Icon} data={linkedInLink} />
                )}
                {youTubeLink.link && (
                  <YoutubeLink
                    className={`${styles.Icon} ${styles.LowerIcon}`}
                    data={youTubeLink}
                  />
                )}
                {facebookLink.link && (
                  <FacebookLink data={facebookLink} className={styles.Icon} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MobileNav as any;
