import React, {  Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Bind } from 'lodash-decorators';
import root from "window-or-global";
import styles from './Pagination.module.scss';
import CaseStudiesStore from "stores/caseStudies.store";

export interface CaseStudiesListingProps {
	caseStudiesStore: CaseStudiesStore;
}

@inject('caseStudiesStore')
@observer
class Pagination extends Component<CaseStudiesListingProps> {

	@Bind
	onPageClick(pageNumber: number) {
		const {
			caseStudiesStore: {
				setPage,
			},
		} = this.props;
		setPage(pageNumber);
		root.scrollTo(0, 0);
	}

	render() {
		const {
			caseStudiesStore: {
				pagesCount,
				page,
			},
		} = this.props;

		return (
			<div className={styles.Container}>
				{
					pagesCount > 1&&
					Array.from({length: pagesCount}, (v, k) => k + 1)
						.map((pageNumber: number) => (
							<span
								onClick={() => this.onPageClick(pageNumber)}
								className={`${styles.PageNumber} ${pageNumber === page ? styles.Active : ''}`}
							>
								{pageNumber}
							</span>
						))
				}
			</div>
		);
	}
}


export default Pagination as any;
// type X = Pagination extends Component<{
// 	caseStudiesStore?: CaseStudiesStore; // TODO typing
// }>;

// export default Pagination<T extends Pagination>;
