import React, {  Component } from 'react';
import { inject, observer } from 'mobx-react';
import SEO from 'blocks/SEO/SEO';
import { ROUTER_PARAM } from 'common/consts/common';
import { slashesToDashes } from "common/methods/string";
import Layout from "components/Layout/Layout";
import Navbar from "blocks/Navbar/Navbar";
import CoreStore from "stores/core.store";
import BlogStore from 'stores/blog.store';
import WindowStore from "stores/window.store";
import ViewStore from "stores/view.store";
import { ArticleBox } from "blocks/ArticleBox/ArticleBox";
import ArticleContent from "containers/BlogArticle/ArticleContent/ArticleContent";
import FooterSection from "blocks/FooterSection/FooterSection";
import PartnersSection from "blocks/PartnersSection/PartnersSection";
import ArticleHeader from './ArticleHeader/ArticleHeader';
import BlogAuthor from "blocks/BlogAuthor/BlogAuthor";
import Newsletter from "blocks/Newsletter/Newsletter";
import styles from './BlogArticle.module.scss';

export interface BlogArticleProps {
	blogStore: BlogStore;
	coreStore: CoreStore;
	windowStore: WindowStore;
	viewStore: ViewStore;
	slug?: string;
	match: {
		params: {
			category: string;
			slug: string
		}
	};
	path?: string;
}

@inject('coreStore', 'blogStore', 'windowStore', 'viewStore')
@observer
class BlogArticle extends Component<BlogArticleProps> {
	constructor(props) {
		super(props);
		props.blogStore.getArticleList();
		props.blogStore.getArticle(props.match.params[ROUTER_PARAM.SLUG]);
	}

	componentDidUpdate(prevProps) {
		const {
			blogStore,
			coreStore,
		} = this.props as any;
		// fckd up typings in props :(
		const slug = this.props.match.params[ROUTER_PARAM.SLUG] || '';
		const prevSlug =  prevProps.match.params[ROUTER_PARAM.SLUG] ||  '';
		const category = this.props.match.params[ROUTER_PARAM.CATEGORY] || '';
		if (slug !== prevSlug) {
			blogStore.getArticle(slug);
		}

		if (blogStore.currentArticle
			&& slashesToDashes(category) !== blogStore.currentArticle.mainCategory.codename) {

			coreStore.setContainerFound(false);
			blogStore.currentArticle = null;
			return;
		}
	}

	render() {
		const {
			windowStore: {
				mediaSmallDesktop,
			},
			coreStore: {
				data,
			},
			blogStore: {
				isArticleLoading,
				articleList,
				currentArticle,
			},
		} = this.props as any;
		// fckd up typings in props :(
		const slug = this.props.match.params.slug || '';
		if (isArticleLoading || !currentArticle) {
			return null;
		}

		const otherArticles = articleList
			.filter((otherArticle) => otherArticle.id !== currentArticle.id)
			.slice(0, mediaSmallDesktop ? 3 : 2);

		return (
			<div>
				{currentArticle.SEO && <SEO data={currentArticle.SEO} slug={slug} />}
				{data.navigation && <Navbar data={data.navigation} />}
				<Layout>
					<article className={styles.Container}>
						<ArticleHeader
							data={currentArticle}
						/>
						<ArticleContent
							data={currentArticle}
						/>
						{currentArticle.author && <BlogAuthor data={currentArticle.author} />}
						<aside>
							{otherArticles.map((otherArticle) => (
								<ArticleBox
									data={otherArticle}
									className={styles.ArticleBox}
								/>
							))}
						</aside>
					</article>
				</Layout>
				{data.newsletter && <Newsletter data={data.newsletter} />}
				<PartnersSection
					className={styles.Partners}
					data={data.partners}
				/>
				{data.footer && <FooterSection data={data.footer} />}
			</div>
		);
	}
}

export default BlogArticle as any;