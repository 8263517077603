import anime from 'animejs';

export const dropElement = (targets, delay) => anime({
	targets,
	translateY: [
		`${25 - (Math.random() * 50)}vh`,
		0,
	],
	scale: [0, 1],
	rotate: () => [90 - Math.random() * 180, 0],
	delay,
	duration: 1500,
});

export const hide = (targets, duration = 100) => anime({
	targets,
	opacity: [1, 0],
	duration,
	easing: 'linear',
});

export const show = (targets, duration = 300) => anime({
	targets,
	opacity: [0, 1],
	duration,
	easing: 'linear',
});
