/* eslint-disable react/prop-types */
import React, {  Component } from 'react';
import { Bind } from 'lodash-decorators';
import { Newsletter as NewsletterModel } from 'models';
import { FORM_STATE, SUCCESS_MESSAGE_TIMEOUT } from 'common/consts/form';
import Button from 'components/Buttons/Button/Button';
import Layout from 'components/Layout/Layout';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import MailingService from 'services/mailing.service';
import styles from './Newsletter.module.scss';
import Input from 'components/Input/Input';
import CheckBox from 'components/CheckBox/CheckBox';
import RichTextParser from 'components/RichTextParser/RichTextParser';

export interface NewsletterProps {
	data: NewsletterModel;
	className?: string;
}

@observer
class Newsletter extends Component<NewsletterProps> {
	email = observable.box('');

	processingDataCheckBox = observable.box(false);

	commercialInfoCheckBox = observable.box(false);

	directMarketingCheckBox = observable.box(false);

	@observable formState = FORM_STATE.DEFAULT;

	@observable resetValue = false;

	@observable formDirty = false;

	constructor(props) {
		super(props);
		this.resetForm();
		this.setInitialState();
	}

	setInitialState() {
		this.formState = FORM_STATE.DEFAULT;
	}

	resetForm() {
		this.email.set('');
		this.processingDataCheckBox.set(false);
		this.commercialInfoCheckBox.set(false);
		this.directMarketingCheckBox.set(false);
	}

	@Bind
	async submitForm(submitEvent) {
		submitEvent.preventDefault();

		if (this.formState === FORM_STATE.ERROR) {
			this.formState = FORM_STATE.DEFAULT;
			return;
		}

		if (this.formState !== FORM_STATE.DEFAULT) {
			return;
		}

		this.formState = FORM_STATE.LOADING;

		try {
			await MailingService.saveNewsletterEmail({
				email: this.email.get(),
			});

			this.formState = FORM_STATE.SUCCESS;

			setTimeout(() => {
				this.setInitialState();
			}, SUCCESS_MESSAGE_TIMEOUT);

			this.resetValue = false;
			this.resetForm();
		} catch (e) {
			this.formState = FORM_STATE.ERROR;
		}
	}

	render() {
		const {
			className,
			data: {
				headerText,
				smallDescription,
				emailLabel,
				processingDataCheckBoxText,
				commercialInfoCheckBoxText,
				directMarketingCheckBoxText,
				signInText,
				signErrorText,
				signSuccessText,
				signingInText,
			},
		} = this.props;

		let buttonText = signInText;

		switch (this.formState) {
		case FORM_STATE.ERROR:
			buttonText = signErrorText;
			break;
		case FORM_STATE.LOADING:
			buttonText = signingInText;
			break;
		case FORM_STATE.SUCCESS:
			buttonText = signSuccessText;
			break;
		default:
			buttonText = signInText;
			break;
		}

		return (
			<Layout className={className || styles.LayoutStyle}>
				<section className={styles.MainContainer}>
					<h3 className={styles.MainHeading}>
						{headerText}
					</h3>
					<p className={styles.SmallDescription}>
						<RichTextParser
							content={smallDescription}
						/>
					</p>
					<form
						onInput={() => { this.formDirty = true; }}
						onSubmit={(e) => this.submitForm(e)}
					>
							<Input
								label={emailLabel}
								valueObservableBox={this.email}
								type="email"
								className={styles.Input}
								required
							/>
					<Button type="submit" className={styles.Button}>
						{buttonText}
					</Button>
					<div className={this.formDirty
								? styles.CheckBoxPanelVisible
								: styles.CheckBoxPanelHidden
							}
							>
							<CheckBox
								required
								valueObservableBox={this.processingDataCheckBox}
								safeHtmlLabel={processingDataCheckBoxText}
								className={styles.Agreement}
							/>
							<CheckBox
								required
								valueObservableBox={this.commercialInfoCheckBox}
								safeHtmlLabel={commercialInfoCheckBoxText}
								className={styles.Agreement}
							/>
							<CheckBox
								required
								valueObservableBox={this.directMarketingCheckBox}
								safeHtmlLabel={directMarketingCheckBoxText}
								className={styles.Agreement}
							/>
						</div>
					</form>
				</section>
			</Layout>
		);
	}
}

export default Newsletter;
