import React, {  FunctionComponent } from 'react';
import OuterLink from 'models/OuterLink';
import Icon from 'components/Icon/Icon';
import faFacebookF from '@fortawesome/fontawesome-free-brands/faFacebookF';
import LinkParser from 'components/LinkParser/LinkParser';

export interface FacebookLinkProps {
	data: OuterLink;
	className?: string;
}

const FacebookLink: FunctionComponent<FacebookLinkProps> = ({ data, className }) => {
	return (
		<LinkParser
			className={className}
			data={data}
		>
			<Icon icon={faFacebookF} />
		</LinkParser>
	);
};

FacebookLink.defaultProps = {
	className: '',
};

export default FacebookLink;
