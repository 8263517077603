import React from 'react';
import { KENTICO_TYPES } from 'common/consts/kentico';
import HeroSection from 'blocks/HeroSection/HeroSection';
import ThreeColumnsWithHeaderSection from 'blocks/ThreeColumnsWithHeaderSection/ThreeColumnsWithHeaderSection';
import TwoColumnsWithHeaderSection from 'blocks/TwoColumnsWithHeaderSection/TwoTextColumnsWithHeaderSection';
import SliderSection from 'blocks/SliderSection/SliderSection';
import SliderSectionWithHeader from 'blocks/SliderSectionWithHeader/SliderSectionWithHeader';
import Navigator from 'blocks/Navigator/Navigator';
import ContactFormSection from 'blocks/ContactFormSection/ContactFormSection';
import FooterSection from 'blocks/FooterSection/FooterSection';
import Navbar from 'blocks/Navbar/Navbar';
import MultipleColumnsSection from 'blocks/MultipleColumnsSection/MultipleColumnsSection';
import PartnersSection from 'blocks/PartnersSection/PartnersSection';
import BlogListing from 'blocks/BlogListing/BlogListing';
import PromoSection from 'blocks/PromoSection/PromoSection';
import Newsletter from 'blocks/Newsletter/Newsletter';
import RichTextWithTitle from "blocks/RichTextWithTitle/RichTextWithTitle";
import MultipleColumnsSectionWithHeader from 'blocks/MultipleColumnsWithHeaderSection/MultipleColumnsWithHeaderSection';
import SliderWithIcon from 'blocks/SliderWithIcon/SliderWithIcon';
import CollaborationSection from 'blocks/CollaborationSection/CollaborationSection';
import Testimonials from 'blocks/TestimonialsSection/TestimonialsSection';
import CaseStudiesListing from '../../blocks/CaseStudiesListing/CaseStudiesListing';
import FourColumnsSectionWithHeader from 'blocks/FourColumnsWithHeaderSection/FourColumnsWithHeaderSection';
import EmployeeContactSection from 'blocks/EmployeeContactSection/EmployeeContactSection';
import LinkedImage from 'blocks/LinkedImage/LinkedImage';

export const ViewContentParser = ({ content }) => (
	<div> {
		content.map((block) => {
			switch (block.system.type) {
				case KENTICO_TYPES.NAVBAR:
					return <Navbar data={block} key={block.system.id} />;
				case KENTICO_TYPES.TWO_COLUMNS_WITH_HEADER_SECTION:
					return <TwoColumnsWithHeaderSection data={block} key={block.system.id} />;
				case KENTICO_TYPES.THREE_COLUMNS_WITH_HEADER_SECTION:
					return <ThreeColumnsWithHeaderSection data={block} key={block.system.id} />;
				case KENTICO_TYPES.COLLABORATIONS_COLUMNS:
					return <CollaborationSection data={block} key={block.system.id} />;
				case KENTICO_TYPES.TESTIMONIALS:
					return <Testimonials data={block} key={block.system.id} />;
				case KENTICO_TYPES.MULTIPLE_COLUMNS_WITH_HEADER_SECTION:
					return <MultipleColumnsSectionWithHeader data={block} key={block.system.id} />;
				case KENTICO_TYPES.MULTIPLE_COLUMNS_SECTION:
					return <MultipleColumnsSection data={block} key={block.system.id} />;
				case KENTICO_TYPES.HERO_SECTION:
					return <HeroSection data={block} key={block.system.id} />;
				case KENTICO_TYPES.SLIDER_SECTION:
					return <SliderSection data={block} key={block.system.id} />;
				case KENTICO_TYPES.SLIDER_SECTION_WITH_HEADER:
					return <SliderSectionWithHeader data={block} key={block.system.id} />;
				case KENTICO_TYPES.SLIDER_WITH_ICON:
					return <SliderWithIcon data={block} key={block.system.id} />;
				case KENTICO_TYPES.NAVIGATOR:
					return <Navigator data={block} key={block.system.id} />;
				case KENTICO_TYPES.CONTACT_FORM_SECTION:
					return <ContactFormSection data={block} key={block.system.id} />;
				case KENTICO_TYPES.FOOTER_SECTION:
					return <FooterSection data={block} key={block.system.id} />;
				case KENTICO_TYPES.PARTNERS_SECTION:
					return <PartnersSection data={block} />;
				case KENTICO_TYPES.BLOG_LISTING:
					return <BlogListing data={block} />;
				case KENTICO_TYPES.CASE_STUDIES_LISTING:
					return <CaseStudiesListing data={block} />;
				case KENTICO_TYPES.PROMO_SECTION:
					return <PromoSection data={block} />;
				case KENTICO_TYPES.NEWSLETTER:
					return <Newsletter data={block} />;
				case KENTICO_TYPES.RICH_TEXT_WITH_TITLE:
					return <RichTextWithTitle data={block} />;
				case KENTICO_TYPES.FOUR_COLUMNS_WITH_HEADER_SECTION:
					return <FourColumnsSectionWithHeader data={block} key={block.system.id} />;
				case KENTICO_TYPES.EMPLOYEE_CONTACT_SECTION:
					return <EmployeeContactSection data={block} key={block.system.id} />;
				case KENTICO_TYPES.LINKED_IMAGE:
					return <LinkedImage data={block} key={block.system.id} />;
				default:
					return null;
			}
		})
	}
	</div>
);
