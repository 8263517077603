import React, {  Component } from 'react';
import { observer } from 'mobx-react';
import styles from './CheckBox.module.scss';

@observer
class CheckBox extends Component<any> {

	static defaultProps = {
		required: false,
		className: '',
	};

	render() {
		const {
			required,
			safeHtmlLabel,
			className,
			valueObservableBox,
		} = this.props;

		return (
			<div
				className={`${styles.Container} ${className}`}
				onClick={() => {
					valueObservableBox.set(!valueObservableBox.get());
				}}
			>
				<div className={styles.CheckBox}>
					<span
						style={{ opacity: valueObservableBox.get() ? 1 : 0 }}
						className={styles.CheckMark}
					/>
					<input
						className={styles.NativeCheckBox}
						type="checkbox"
						required={required}
						onChange={() => null}
						checked={valueObservableBox.get()}
					/>
				</div>
				<label
					dangerouslySetInnerHTML={{ __html: safeHtmlLabel }}
					className={styles.Label}
				/>
			</div>
		);
	}
}

export default CheckBox;
