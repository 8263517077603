import React, {  FunctionComponent } from 'react';
import HorizontalLine from 'components/HorizontalLine/HorizontalLine';
import styles from './Header.module.scss';

export interface IHeader {
	className?: string;
	text: string;
}

export const Header: FunctionComponent<IHeader> = ({ text, className }) => {
	return (
		<header>
			<h2 className={`${styles.Text} ${className}`}>
				{text}
			</h2>
			<HorizontalLine />
		</header>
	);
};
