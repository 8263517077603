import React, { FunctionComponent } from 'react';
import ArrowButtonProps from 'components/Buttons/Arrow/Props';
import styles from './Arrow.module.scss';

const BackButton: FunctionComponent<ArrowButtonProps> = ({ onClick, className, style, arrowClassName }) => {
	return (
		<button
			onClick={onClick}
			className={`${className} ${styles.ArrowButton}`}
			>
			<i
				className={`${styles.Arrow} ${styles.Left} ${arrowClassName}`}
				style={style} 
				/>
		</button>
	);
};
BackButton.defaultProps = {
	className: '',
	onClick: () => null,
};

export default BackButton;
