export const FORM_STATE = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
  DEFAULT: 'default',
};

export const SUCCESS_MESSAGE_TIMEOUT = 3000;

export const RECORDING_TIME_MS = 60000;
