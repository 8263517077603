import React, { FunctionComponent } from 'react';
import { KENTICO_TYPES } from 'common/consts/kentico';
import { ContentItem } from 'kentico-cloud-delivery';
import PhoneLink from 'blocks/PhoneLink/PhoneLink';
import BlockQuote from 'blocks/BlockQuote/BlockQuote';
import {
	PhoneLink as PhoneLinkModel,
	BlockQuote as BlockQuoteModel,
} from 'models';

export interface RichTextComponentResolverProps {
	item: ContentItem;
}

const RichTextComponentResolver: FunctionComponent<RichTextComponentResolverProps> = ({ item }) => {
	switch (item.type) {
		case KENTICO_TYPES.PHONE_LINK:
			return <PhoneLink data={(item as (PhoneLinkModel))} />;
		case KENTICO_TYPES.BLOCK_QUOTE:
			return <BlockQuote data={(item as (BlockQuoteModel))} />;
		default:
			return null;
	}
};

export default RichTextComponentResolver;
