import React, { Component } from 'react';
import get from 'lodash/get';
import { observer } from 'mobx-react';
import Slide from './Slide/Slide';
import {
	observable,
	computed, action,
} from 'mobx';

import BackButton from 'components/Buttons/Arrow/BackButton';
import NextButton from 'components/Buttons/Arrow/NextButton';
import { Bind } from 'lodash-decorators';
import styles from './Slider.module.scss';

@observer
export default class Slider extends Component<any> {

  @observable currentSlideIndex = 0;

  slideRef: Slide | null = null;
  
  state = {
    slides: this.props.slides,
  }

  @computed
	get currentSlide() {
		return this.props.slides[this.currentSlideIndex];
	}

	@Bind
	@action
	async goPreviousSlide() {
		if (this.slideRef) {
			await this.slideRef.hideToRight();
			this.currentSlideIndex = !this.currentSlideIndex
				? this.state.slides.length - 1
				: this.currentSlideIndex -= 1;
			await this.slideRef.showToRight();
		}
	}

	@Bind
	@action
	async goNextSlide() {
		if (this.slideRef) {
			await this.slideRef.hideToLeft();
			this.currentSlideIndex = this.currentSlideIndex === (this.state.slides.length - 1)
				? 0
				: this.currentSlideIndex + 1;
			await this.slideRef.showToLeft();
		}
	}

	render() {
		const {     
      buttonsWrapperClassName,
      isQuotation,
    } = this.props

		return (
      <>
        <Slide
          ref={(ref) => this.slideRef = ref}
          data={get(this.currentSlide, 'elements')}
          className={styles.SlideContent}
          isQuotation={isQuotation}
        />
        <div className={`${styles.Buttons} ${buttonsWrapperClassName}`}>
          <BackButton
            onClick={this.goPreviousSlide}
            className={styles.Button}
            arrowClassName={styles.Button}
          />
          <NextButton
            onClick={this.goNextSlide}
            className={styles.Button}
            arrowClassName={styles.Button}
          />
        </div>
      </>
		);
	}
}
