import React, {  Component } from 'react';
import { observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { Bind } from 'lodash-decorators';
import Icon from 'components/Icon/Icon';
import faCheckIcon from '@fortawesome/fontawesome-free-solid/faCheck';
import styles from './Input.module.scss';

export interface InputProps {
	required?: boolean;
	type?: string;
	pattern?: string;
	label?: string;
	className?: string;
	valueObservableBox?: any;
}

@observer
class Input extends Component<InputProps> {

	static defaultProps = {
		required: false,
		type: 'text',
		className: '',
		pattern: null,
	};

	@observable focused = false;

	@observable touched = false;

	@observable valid = false;

	inputRef;
	disposeFocusReaction;

	componentDidMount() {
		const { valueObservableBox } = this.props;
		if (this.inputRef.type === 'textarea') {
			this.disposeFocusReaction = reaction(() => this.focused, () => {
				if (!(valueObservableBox.get().indexOf('\n') > -1 || valueObservableBox.get().indexOf('\r') > -1)
					|| this.inputRef.value.length === 0) {
					if (this.inputRef) {
						this.inputRef.style.height = '32px';
					}
				}
			});
		}
	}

	componentWillUnmount() {
		this.disposeFocusReaction && this.disposeFocusReaction();
	}

	@Bind
	autoSize() {
		if (this.inputRef.type === 'textarea') {
			setTimeout(() => {
				this.inputRef.style.height = 'auto';
				this.inputRef.style.height = `${this.inputRef.scrollHeight}px`;
				if (this.inputRef.value.length === 0) {
					this.inputRef.style.height = '32px';
				}
			}, 0);
		}
	}

	@Bind
	handleFocus() {
		this.focused = true;
	}

	@Bind
	handleBlur() {
		this.focused = false;
		if (this.touched) {
			this.valid = this.inputRef.checkValidity();
		}
	}

	@Bind
	handleInputOrChange(ev: React.FormEvent | React.ChangeEvent) {
		const { valueObservableBox } = this.props;
		this.inputRef.type !== 'textarea'
			? valueObservableBox.set((ev.target as HTMLInputElement).value)
			: valueObservableBox.set((ev.target as HTMLTextAreaElement).value);
		this.touched = true;
		this.valid = this.inputRef.checkValidity();
	}

	@Bind
	handleInvalid() {
		this.valid = false;
		this.touched = true;
	}

	render() {
		const {
			required,
			type,
			pattern,
			label,
			className,
			valueObservableBox,
		} = this.props;

		return (
			<label className={`${styles.Container} ${className}`}>
				<span className={`${valueObservableBox.get() || this.focused
					? `${styles.Label} ${styles.LabelActive}`
					: styles.Label}
						${required && this.touched && !this.valid ? styles.LabelInvalid : ''}
						`}
				>
					{label}
					{required && <span style={{ marginLeft: '5px' }}>*</span>}
				</span>
				<Icon
					icon={faCheckIcon}
					className={`
						${styles.CheckIcon}
						${!this.valid ? styles.CheckIconHidden : ''}
					`}
				/>
				{
					type !== 'textarea' ? (
						<input
							ref={(ref) => this.inputRef = ref}
							type={type}
							required={required}
							pattern={pattern}
							value={valueObservableBox.get()}
							onFocus={this.handleFocus}
							onInvalid={this.handleInvalid}
							onBlur={this.handleBlur}
							onChange={(ev: React.ChangeEvent) => this.handleInputOrChange(ev)}
							onInput={(ev: React.FormEvent | React.ChangeEvent) => this.handleInputOrChange(ev)}
							className={`
									${styles.Input}
									${this.touched && !this.valid ? styles.Touched : ''}
								`}
						/>
					) : (
							<textarea
								ref={(ref) => this.inputRef = ref}
								required={required}
								value={valueObservableBox.get()}
								onFocus={this.handleFocus}
								onInvalid={this.handleInvalid}
								onBlur={this.handleBlur}
								onChange={(ev: React.FormEvent | React.ChangeEvent) => this.handleInputOrChange(ev)}
								onInput={(ev: React.FormEvent | React.ChangeEvent) => {
									this.autoSize();
									this.handleInputOrChange(ev);
								}}
								className={`
									${styles.Input}
									${this.touched && !this.valid ? styles.Touched : ''}
								`}
							/>
						)
				}
			</label>
		);
	}
}


export default Input;
