import { animationEndTrigger } from 'common/animations/methods';
import get from 'lodash/get';
import Layout from 'components/Layout/Layout';
import Asset from 'components/Asset/Asset';
import RichTextParser from 'components/RichTextParser/RichTextParser';
import { Bind } from 'lodash-decorators';
import { observer } from 'mobx-react';
import { Slide as SlideModel } from 'models';
import React, { Component } from 'react';
import styles from './Slide.module.scss';

export interface ISlideProps {
	data: SlideModel;
  className?: string;
  isQuotation: boolean;
}

@observer
class Slide extends Component<ISlideProps> {

	static defaultProps = {
    className: '',
    isQuotation: false,
	};

	layoutRef: any = React.createRef();
	slideRef: any;

	animations = [
		styles.ShowToLeft,
		styles.ShowToRight,
		styles.HideToLeft,
		styles.HideToRight,
		styles.Hide,
		styles.Show,
	];

	clearAnimations() {
		this.layoutRef.classList.remove(...this.animations);
		this.slideRef.classList.remove(...this.animations);
	}

	@Bind
	async hideToLeft() {
		this.clearAnimations();
		this.layoutRef.classList.add(styles.HideToLeft);
		this.slideRef.classList.add(styles.Hide);
		await animationEndTrigger(this.layoutRef);
	}

	@Bind
	async hideToRight() {
		this.clearAnimations();
		this.layoutRef.classList.add(styles.HideToRight);
		this.slideRef.classList.add(styles.Hide);
		await animationEndTrigger(this.layoutRef);
	}

	@Bind
	async showToLeft() {
		this.clearAnimations();
		this.layoutRef.classList.add(styles.ShowToLeft);
		this.slideRef.classList.add(styles.Show);
		await animationEndTrigger(this.layoutRef);
	}

	@Bind
	async showToRight() {
		this.clearAnimations();
		this.layoutRef.classList.add(styles.ShowToRight);
		this.slideRef.classList.add(styles.Show);
		await animationEndTrigger(this.layoutRef);
	}

	@Bind
	setRef(ref) {
		this.layoutRef = ref;
  }

	render() {
		const {
      className,
      isQuotation,
    } = this.props;
		return (
			<div
				className={className}
				ref={(ref) => this.slideRef = ref }
			>
        <Layout setRef={this.setRef} className={styles.Layout}>
          <div className={`${isQuotation ? '' : styles.ReversedOnMobile} ${styles.SliderWrapper}`}>
            {isQuotation ?
            <>
              <div className={styles.SliderIntro}>
                <img
                  src={get(this.props, 'data.logo.value[0].url')}
                  alt={get(this.props, 'data.logo.value[0].name')}
                  className={styles.Image} />
                  {this.props.children}
                {get(this.props, 'data.description.value') && <RichTextParser
                  content={get(this.props, 'data.description.value')} className={styles.Description} />}
              </div>
              <RichTextParser className={styles.Quotation} content={get(this.props, 'data.quatation.value')}  />
            </> :
            <>
              <div className={styles.SliderIntro}>
                <div className={styles.SlideTitle}>
                  <h3 className={styles.Name}>{get(this.props, 'data.name.value')}</h3>
                </div>
					      <div className={styles.DescriptionSection}>
                <RichTextParser content={get(this.props, 'data.description.value')} />
              </div>
            </div>
            <Asset
              className={`${styles.AssetContainer} ${styles.AssetContainerOverButton}`}
              assetClassName={`${styles.AssetStyle} ${styles.AssetOverButtonStyle}`}
              data={get(this.props, 'data.background_image.value[0]')}
            />
          </>}
        </div>
     </Layout>
	</div>);
	}
}

export default Slide;
