import React, { FunctionComponent } from "react";
import { LinkedImage as LinkedImageModel } from "models";

export interface LinkedImageProps {
  data: LinkedImageModel;
}

const LinkedImage: FunctionComponent<LinkedImageProps> = ({
  data: { link, image, newWindow },
}) => {
  return (
    <React.Fragment>
      <a
        href={link}
        target={newWindow ? "_blank" : "_self"}
        rel="noopener noreferrer nofollow"
      >
        <img src={image.url} alt={image.description} />
      </a>
    </React.Fragment>
  );
};

export default LinkedImage as any;
