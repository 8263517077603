import get from 'lodash/get';
import ContentItemWithResolver from "models/ContentItemWithResolver";

export default class ContentItemWithColorPickers extends ContentItemWithResolver {
	get textColor(): string {
		return get(this, '_text_color_picker__text_color.value[0].codename', null);
	}

	get backgroundColor(): string {
		return get(this, '_background_color_picker__background_color.value[0].codename', null);
	}
}
