import React, { Component } from 'react';
import Layout from 'components/Layout/Layout';
import Background from 'components/Background/Background';
import {
	SliderSectionWithHeader as SliderSectionModel,
} from 'models';
import Slider from 'components/Slider/Slider';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import styles from './SliderSectionWithHeader.module.scss';
import LinkParser from "../../components/LinkParser/LinkParser";
import Button from "../../components/Buttons/Button/Button";

export interface ISliderSectionProps {
	data: SliderSectionModel;
}

export default class SliderSection extends Component<ISliderSectionProps> {

	render() {
		const {
			data: {
				headerText,
				slides,
				contactButtonLink
			},
    } = this.props;
		return (
      <Background
        data={{ backgroundColor: 'white', textColor: '' }}
        className={styles.Container}>
        <Layout className={styles.Layout}>
			<div className={styles.TitleWithButtonWrapper}>
				<SectionTitle title={headerText} />
				{ contactButtonLink ? (
					<LinkParser
						data={contactButtonLink}
						className={styles.TitleWithButtonWrapper__ButtonPosition}
					>
						<Button type="button">
							{contactButtonLink.text}
						</Button>
					</LinkParser>
				) : null
				}
			</div>
				<Slider
					slides={slides}
					buttonsWrapperClassName={styles.Buttons}
				/>
        </Layout>
			</Background>
		);
	}
}
