import { action, computed, observable } from 'mobx';
import CoreService from 'services/core.service';
import WindowStore from 'stores/window.store';
import NavigatorStore from 'stores/navigator.store';
import { KENTICO_TAXONOMY } from 'common/consts/kentico';
import { FieldModels } from 'kentico-cloud-delivery/_es2015';
import { Core, Translations } from 'models';
import BlogStore from './blog.store';
import ViewStore from './view.store';
import CaseStudiesStore from "./caseStudies.store";

type TaxonomyTerm = FieldModels.TaxonomyTerm;

class CoreStore {
	@observable data: Core | any;

	@observable initialCoreDataFetchDone = false;

	@observable taxonomies: Map<string, TaxonomyTerm[]> = new Map();

	@observable translations: Translations | any;

	@observable containerFound: boolean = true;

	@observable previousURL: any = null;

	@observable currentURL: any = null;

	@observable referrerURL: any = null;

	public viewStore: ViewStore;
	public windowStore: WindowStore;
	public navigatorStore: NavigatorStore;
	public blogStore: BlogStore;
	public caseStudiesStore : CaseStudiesStore;
	public history;

	constructor(history) {
		this.windowStore = new WindowStore();
		this.viewStore = new ViewStore(this);
		this.blogStore = new BlogStore(this);
		this.caseStudiesStore = new CaseStudiesStore(this);
		this.navigatorStore = new NavigatorStore(this);
		this.history = history;

		Promise.all([this.getCoreData(), this.getTaxonomyData()])
			.then(() => {
				this.translations = this.data.translations;
				this.initialCoreDataFetchDone = true;
		});
	}

	@action.bound
	public setContainerFound(isFound) {
		this.containerFound = isFound;
	}

	@action.bound
	private async getCoreData() {
		this.data = await CoreService.fetchCoreData();
	}

	@action.bound
	private async getTaxonomyData() {
		this.taxonomies = new Map((await CoreService.fetchTaxonomyData())
			.map((taxonomyGroup) => [taxonomyGroup.system.codename, taxonomyGroup.terms]));
	}

	@computed
	public get isLoading() {
		return !this.initialCoreDataFetchDone || this.viewStore.isViewLoading || this.blogStore.isArticleLoading || this.caseStudiesStore.isCaseStudyLoading;
	}

	@action
	setUrlHistory() {
		this.referrerURL = document.referrer;
		if (this.currentURL !== window.parent.location.href) {
			this.previousURL = this.currentURL;
		}
		this.currentURL = window.parent.location.href;
	}
}

export default CoreStore;
