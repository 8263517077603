import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { CaseStudyArticle } from 'models';
import Asset from 'components/Asset/Asset';
import { Tag } from 'components/Tag/Tag';
import { LawTag } from 'components/LawTag/LawTag';
import ANCHOR from "common/consts/anchor";
import { Markup } from 'components/Markup/Markup';
import { NBSPAfterSingleLetters, underscoresToDashes } from 'common/methods/string';
import styles from './CaseStudyBox.module.scss';
import { translatedValueList } from './utils';
import CaseStudiesStore from "../../stores/caseStudies.store";
import CoreStore from "stores/core.store";
import { DropDownValue } from "models/DropDownValue";

export interface CaseStudyBoxProps {
	data: CaseStudyArticle;
	className?: string;
	caseStudiesStore: CaseStudiesStore;
	coreStore: CoreStore;
	type: string;
}

@inject('caseStudiesStore', 'coreStore')
class CaseStudyBox extends Component<CaseStudyBoxProps> {

	state = {
		sectionTags: [],
		lawTags: []
	}

	componentDidMount() {
		const { data, caseStudiesStore } = this.props;
		const sectionTags = translatedValueList(caseStudiesStore.caseStudiesSections, data.categories);
		const lawTags = translatedValueList(caseStudiesStore.caseStudiesLaw, data.lawCategories);

		this.setState({ sectionTags, lawTags });
	}

	render() {
		const {
			className,
			data,
			coreStore: {
				data: {
					translations: {
						global
					}
				},
			},
		} = this.props;
		const { sectionTags, lawTags } = this.state;
		const category = underscoresToDashes(data.mainCategory.codename);
		const buttonTranslation = global[0].buttonReadArticle;
		const { type } = this.props;

		return (
			<a
				className={`${className} ${styles.Container} ${type === 'small' && styles.SmallBox}`}
				href={`${ANCHOR.CASE_STUDIES}/${category}/${data.slug}`}
			>
				<div className={`${type === 'small' ? styles.SmallRelativeAssetContainer : styles.RelativeAssetContainer}`}>
					<Asset
						className={styles.ImageContainer}
						assetClassName={styles.Image}
						data={data.image}
					/>
					<div className={styles.Tags}>
						{sectionTags.map((tag: DropDownValue) => (
							<Tag className={styles.Tag}>{tag.representation}</Tag>
						))}
					</div>
				</div>
				<div className={styles.TextContainer}>
					<h2 className={styles.Title}>
						<Markup
							wrap={false}
							markup={NBSPAfterSingleLetters(data.title)}
						/>
					</h2>
					<div className={styles.LawTags}>
						{lawTags.map((tag: DropDownValue) => (
							<LawTag>{tag.representation}</LawTag>
						))}
					</div>
					<p className={styles.BottomText}>
						<Markup
							wrap={false}
							markup={NBSPAfterSingleLetters(data.lead)}
						/>
					</p>
					{
						type !== 'small' &&
						<div className={styles.BtnLikeLink}>{buttonTranslation}</div>
					}
				</div>
			</a>
		);
	}
};

export default CaseStudyBox as any;