import React, { Component } from 'react';
import { inject } from 'mobx-react';
import WindowStore from "stores/window.store";
import styles from './MultiSelect.module.scss';
import { DropDownValue } from "models/DropDownValue"
import DownButton from "components/Buttons/Arrow/DownButton";
import root from "window-or-global";

export interface MultiSelectProps {
  windowStore: WindowStore;
  placeholder: string;
  options: DropDownValue[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>, option: string) => any;
  filterType: string;
}

interface MultiSelectState {
  open: boolean;
  selectedOptions: string[];
}

@inject('windowStore',)
class MultiSelect extends Component<MultiSelectProps, MultiSelectState> {

  state: MultiSelectState = {
    selectedOptions: [],
    open: false
  }

  wrapperRef: any = React.createRef();

  componentDidMount() {
    root.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    root.removeEventListener('click', this.handleClickOutside);
  }

  onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, option: string) => {
    const {
      onChange,
    } = this.props;

    onChange(event, option);

    if (event.target.checked && option) {
      return this.setState(prevState =>
        ({ selectedOptions: [...prevState.selectedOptions, option] }))
    };
    return this.setState(prevState =>
      ({ selectedOptions: prevState.selectedOptions.filter(optionVal => optionVal !== option) }));
  }

  toggleDropDown = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  }

  handleClickOutside = (event) => {
    if (this.state.open !== false) {
      if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
        this.setState({ open: false });
      }
    }
  }

  render() {
    const {
      placeholder,
      options
    } = this.props;

    const {
      selectedOptions,
      open
    } = this.state;

    return (
      <div
        className={styles.Container}
        ref={this.wrapperRef}
      >
        <div
          className={`${styles.Placeholder} ${selectedOptions.length > 0 && styles.Selected}`}
          onClick={this.toggleDropDown}
        >
          {placeholder}
          <DownButton
            className={`
              ${styles.ArrowButton} 
              ${open && styles.Active}
              ${selectedOptions.length > 0 && styles.Selected}`} />
        </div>
        <div className={`${styles.OptionsWrapper} ${open && styles.Open}`}>
          <ul className={styles.OptionsList}>
            {
              options.map((option: DropDownValue, i: number) => (
                <li key={i}>
                  <label className={`${styles.Checkbox} ${selectedOptions.includes(option.value) && styles.Checked}`}>
                    <input
                      type="checkbox"
                      onChange={(event) => this.onChangeHandler(event, option.value)}
                    />
                    {option.representation}
                    <span className={styles.Checkmark}></span>
                  </label>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    );
  }
};

export default MultiSelect as any;