import get from 'lodash/get';
import ContentItemWithResolver from "models/ContentItemWithResolver";
import PromoSection from "models/PromoSection";

export default class CaseStudiesListing extends ContentItemWithResolver {
	get caseStudyHeader() {
		return get(this, '_case_study_header.value', '');
	}
	get caseStudySubHeader() {
		return get(this, '_case_study_subheader.value', '');
	}

	get promoSection(): PromoSection {
		return get(this, '_promo_section[0]', null);
	}

	get filterSelectAllText(): string {
		return get(this, '_filter_select_all_text.value', '');
	}

	get filterSelectAllLawText(): string {
		return get(this, '_filter_select_all_law.value', '');
	}

	get buttonText(): string {
		return get(this, '_case_study_box_button_text.value', '');
	}
}
