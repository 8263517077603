import get from 'lodash/get';
import ContentItemWithColorPickers from './ContentItemWithColorPickers';

export default class FourColumnsWithHeaderSection extends ContentItemWithColorPickers {


	get bigHeading() {
		return get(this, '_big_header.value', '');
	}

	get heading() {
		return get(this, '_heading.value', '');
	}

	get subHeading() {
		return get(this, '_subheading.value', '');
	}

	get columns() {
		return get(this, '_columns', []);
	}
	get button() {
		return get(this, '_button[0]', []);
	}

	get backgroundAsset() {
		return get(this, '_background_asset.assets[0]', null);
	}

}
