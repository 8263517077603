import React, {  FunctionComponent } from "react";
import { EmployeeContactSection as EmployeeContactSectionModel } from "models";
import Layout from "components/Layout/Layout";
import Background from "components/Background/Background";
import { Markup } from "components/Markup/Markup";
import { NBSPAfterSingleLetters } from "common/methods/string";
import styles from "./EmployeeContactSection.module.scss";
import Asset from "../../components/Asset/Asset";
import ANCHOR from "../../common/consts/anchor";

export interface EmployeeContactSectionProps {
  data: EmployeeContactSectionModel;
}

const EmployeeContactSection: FunctionComponent<EmployeeContactSectionProps> = ({
  data
}) => {
  const {
    bigHeader,
    employeeImage,
    name,
    position,
    telephone,
    email,
    textColor,
    backgroundColor,
  } = data;

  return (
    <Background
      data={{ backgroundColor, textColor }}
      className={styles.Container}
    >
      <Layout>
         <h2 className={`${textColor==='secondary__default_text_color_'? styles.PrimaryColor : ""} ${styles.BigHeading}`}>{bigHeader}</h2>
        <div className={styles.EmployeeWrapper}>
          {employeeImage &&
          <Asset
              data={employeeImage}
              className={styles.AssetContainer}
              assetClassName={styles.BackgroundAsset}
          />
          }
          <div className={styles.InfoColumn}>
            <p className={styles.EmployeeName}>{name}</p>
            <p>{position}</p>
            <a href={`tel:${telephone}`}>{telephone}</a>
            <a href={`mailto:${email}`}>{email}</a>
          </div>
        </div>

      </Layout>
    </Background>
  );
};

export default EmployeeContactSection;
