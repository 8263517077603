import get from 'lodash/get';
import ContentItemWithResolver from 'models/ContentItemWithResolver';
import { FieldModels } from 'kentico-cloud-delivery';
import SEO from './SEO';
import BlogAuthor from "models/BlogAuthor";
type AssetModel = FieldModels.AssetModel;
type TaxonomyTerm = FieldModels.TaxonomyTerm;

export default class CaseStudyArticle extends ContentItemWithResolver {
	get SEO(): SEO {
		return get(this, '_seo[0]', null);
	}

	get image(): AssetModel {
		return get(this, '_image.assets[0]', null);
	}

	get publicationDate() {
		return get(this, '_publication_date.value', '');
	}

	get title(): string {
		return get(this, '_title.value', '');
	}

	get content() {
		return get(this, '_content', null);
	}

	get lead() {
		return get(this, '_lead.value', '');
	}

	get categories(): TaxonomyTerm[] {
		return get(this, '_categories_case_studies__case_studies_sections.value', []);
	}

	get lawCategories(): TaxonomyTerm[] {
		return get(this, '_law_categories_case_studies__case_studies_law.value', []);
	}

	get mainCategory(): TaxonomyTerm {
		return get(this, '_main_category_case_studies__case_studies_branch_of_law.value[0]', '');
	}

	get client(): TaxonomyTerm {
		return get(this, '_client', '');
	}

	get author(): BlogAuthor {
		return get(this, '_article_author[0]', null);
	}

	get slug() {
		return get(this, '_slug.value', '');
	}
}
