import React, { FunctionComponent } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousel.module.scss';

const defaultSettings = {
	infinite: true,
	speed: 500,
	slidesToScroll: 1,
};

export interface CarouselProps {
	settings?: any;
  className?: string;
  slideRef?: any;
  children?: any;
  beforeChange?: (before: number, next: number) => void;
  afterChange?: (before: number, next?: number) => void;
  draggable?: boolean;
  infinite?: boolean;
  autoplay?: boolean;
  fade?: boolean;
  autoplaySpeed?: number;
  slidesToScroll?: number;
}

const Carousel: FunctionComponent<CarouselProps> = (props) => {
  const {
    slideRef,
    className,
    children,
    settings,
  } = props;

  const sliderSettings = { ...defaultSettings, ...settings };
  return (
    <Slider
      {...sliderSettings}
      ref={slideRef}
      className={className}
      {...props}
    >
      {children}
    </Slider>
  );
};

export default Carousel;
