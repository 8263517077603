import { KENTICO_TYPES } from 'common/consts/kentico';
import Client from './kenticoClient';

class CoreService {
	static async fetchCoreData() {
		const items = await Client.items()
			.type(KENTICO_TYPES.CORE)
			.depthParameter(10)
			.getPromise();

		if (items.isEmpty) {
			return null;
		}

		return items.lastItem;
	}

	static async fetchTaxonomyData() {
		const response = await Client.taxonomies().getPromise();
		return response.taxonomies;
	}
}

export default CoreService;
