import React, {  FunctionComponent } from 'react';
import { isIE } from 'react-device-detect';
import WindowStore from 'stores/window.store';

export interface ResponsiveImageProps {
	className?: string;
	classNameIE?: string;
	innerRef: (r: any) => any;
	src: string;
	alt: string;
	imgConfig?: any;
	onClick?: (e: any) => any;
	onLoad?: (e: any) => any;
	onError?: (e: any) => any;
}

const ResponsiveImage: FunctionComponent<ResponsiveImageProps> = (props) => isIE
	? (
		<div
			ref={(r) => props.innerRef(r)}
			className={`${props.className} ${props.classNameIE}`}
			style={{
				backgroundImage: `url(${WindowStore.buildPhotoURL(1920, props.src)})`,
				backgroundRepeat: 'no-repeat',
			}}
			onClick={props.onClick}
		>
			<img
				style={{ opacity: 0, pointerEvents: 'none'}}
				src={WindowStore.buildPhotoURL(1920, props.src)}
				alt={props.alt}
			/>
		</div>
	)
	: (
	<img
		ref={(r) => props.innerRef(r)}
		className={props.className}
		srcSet={`
								${WindowStore.buildPhotoURL(500, props.src)} 500w,
								${WindowStore.buildPhotoURL(768, props.src)} 768w,
								${WindowStore.buildPhotoURL(1100, props.src)} 1100w,
								${WindowStore.buildPhotoURL(1366, props.src)} 1366w,
								${WindowStore.buildPhotoURL(1920, props.src)} 1920w
							`}
		src={WindowStore.buildPhotoURL(500, props.src)}
		alt={props.alt}
		{...props.imgConfig}
		onLoad={props.onLoad}
		onError={props.onError}
		onClick={props.onClick}
	/>
);
ResponsiveImage.defaultProps = {
	innerRef: () => null,
	className: '',
	imgConfig: {},
	onClick: () => null,
	onError: () => null,
	onLoad: () => null,
	alt: '',
};

export default ResponsiveImage;
