import ContentItemWithResolver from "models/ContentItemWithResolver";
import get from 'lodash/get';

export default class SlideWithIcon extends ContentItemWithResolver {

  get icon() {
    return get(this, '_icon.value[0]', '');
  }

  get subTitle() {
    return get(this, '_subtitle.value', '');
  }

  get description() {
    return get(this, '_description.value', '');
  }

  get link() {
    return get(this, '_link.value[0]', {});
  }
}
