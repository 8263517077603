import React, { FunctionComponent, useState, useRef } from 'react';
import { MultipleColumnsSection as MultipleColumnsSectionModel } from 'models';
import { isMobile } from 'react-device-detect';
import get from 'lodash/get';
import Layout from 'components/Layout/Layout';
import Background from 'components/Background/Background';
import CollaborationCard from 'components/CollaborationCard/CollaborationCard';
import Carousel from 'components/Carousel/Carousel';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import { SlideRef } from 'common/types/types';
import styles from './CollaborationSection.module.scss';
import LinkParser from "../../components/LinkParser/LinkParser";
import Button from "../../components/Buttons/Button/Button";

export interface MultipleColumnsSectionProps {
	data: MultipleColumnsSectionModel;
}

const CollaborationSection: FunctionComponent<any> = (props) => {
  const [currentSlideIndex, setSlide] = useState(0);
  const slideRef = useRef<SlideRef>(null);

  const setCurrentSlide = (slideIndex: number) => {
    setSlide(slideIndex);
    if (slideRef && slideRef.current) {
      slideRef.current.slickGoTo(slideIndex);
    }
  };

  const sliderSettings = {
		className: styles.PartnersContainer,
		slidesToShow: 3,
		slidesToScroll: 1,
    autoplaySpeed: 2500,
		infinite: true,
		autoplay: true,
		arrows: false,
		responsive: [
      {
				breakpoint: 1100,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
				},
			},
		],
  };

	return (
    <Background
      data={{ backgroundColor: 'white', textColor: 'primary' }}
      className={styles.Container}
    >
      <Layout>
          <div className={styles.TitleWithButtonWrapper}>
              {props && <SectionTitle title={get(props, 'data.headerText', '')} />}
              {!isMobile ? (props.data.contactButtonLink ? (
                  <LinkParser
                  data={props.data.contactButtonLink}
                  className={styles.TitleWithButtonWrapper__ButtonPosition}
                  >
                  <Button type="button">
                  {props.data.contactButtonLink.text}
                  </Button>
                  </LinkParser>
                  ) : null
                ) : null
              }
          </div>
          <Carousel {...sliderSettings}
            slideRef={slideRef}
            beforeChange={(before: number, next: number) => {
              setSlide(next);
            }}
            draggable={isMobile}
            className={styles.cardsSections}
          >
            {props && props.data && props.data.collaborationsItems &&
              props.data.collaborationsItems.map(collItem => <CollaborationCard
                key={get(collItem, 'title')}
                backgroundImage={get(collItem, 'backgroundImage')}
                image={get(collItem, 'image')}
                title={get(collItem, 'title')}
                text={get(collItem, 'text')}
              />)}
            </Carousel>
          <div className={styles.NavigationButtons}>
            {props && props.data && props.data.collaborationsItems &&
              props.data.collaborationsItems.map((collItem, index) => (
                <button
                  key={get(collItem, 'title')}
                  className={`${styles.NavigationButton} ${currentSlideIndex === index && styles.isActive}`}
                  onClick={() => setCurrentSlide(index)} />
              ))}
          </div>
          {isMobile ?
              (<div className={styles.BottomContactButtonSection}>
                  { props.data.contactButtonLink ? (
                      <LinkParser data={props.data.contactButtonLink} className={styles.BottomContactButtonSection__ButtonPosition}>
                          <Button>{props.data.contactButtonLink.text} </Button>
                      </LinkParser>
                    ) : null
                  }
              </div>
              ) : null
          }
        </Layout>
      </Background>
	);
};

export default CollaborationSection;
