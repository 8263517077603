import React, {  FunctionComponent } from 'react';
import { ContentItemWithColorPickers as ColorPickersModel } from 'models';
import { COLOR, COLOR_VALUES } from 'common/consts/common';
import styles from './Background.module.scss';

export interface BackgroundProps {
	className?: string;
	data: Pick<ColorPickersModel, 'textColor' | 'backgroundColor'>;
	backgroundImage?: string;
}

export const setColor = (color) => {
	switch (color) {
		case COLOR.WHITE:
			return COLOR_VALUES.WHITE;
		case COLOR.PRIMARY:
			return COLOR_VALUES.PRIMARY;
		case COLOR.SECONDARY:
			return COLOR_VALUES.SECONDARY;
		case COLOR.DEFAULT:
			return '';
		default:
			return '';
	}
};

const Background: FunctionComponent<BackgroundProps> = ({
	className,
	data,
	children,
	backgroundImage,
}) => {
	const {
	textColor,
	backgroundColor,
	} = data;

	return (
		<div
			className={`${styles.Background} ${className}`}
			style={{
				color: setColor(textColor),
				backgroundColor: setColor(backgroundColor),
				backgroundImage,
			}}
		>
			{children}
		</div>
	);
};

export default Background;
