import { DeliveryClient, TypeResolver } from 'kentico-cloud-delivery';

import {
	KENTICO_TYPES,
	PREVIEW_API_KEY,
	PROJECT_ID,
    KENTICO_PROXY_BASE_URL,
} from 'common/consts/kentico';

import {
	SEO,
	View,
	Core,
	OuterLink,
	InnerLink,
	DownloadLink,
	PromoSection,
	ContactFormSection,
	PageNotFound,
	MultipleColumnsWithHeaderSection,
	MultipleColumnsSection,
	TwoColumnsWithHeaderSection,
	ThreeColumnsWithHeaderSection,
	SliderSection,
	Slide,
	HeroSection,
	HeroHeadingText,
	Navigator,
	Navbar,
	VoiceRecorder,
	Column,
	FooterSection,
	PhoneLink,
	BlogArticle,
	PartnersSection,
	BlogListing,
	CaseStudiesListing,
	Translations,
	BlogSectionTranslation,
	GlobalTranslation,
	CaseStudiesSectionTranslation,
	CaseStudiesLawTranslation,
	BlockQuote,
	BlogAuthor,
	Newsletter,
	RichTextWithTitle,
	SliderSectionWithHeader,
	SliderButton,
	SliderButtonsSection,
	SlideWithIcon,
	SliderWithIcon,
	CollaborationSection,
	TestimonialsSection,
	CaseStudyArticle,
	FourColumnsWithHeaderSection,
	EmployeeContactSection,
	CtaContactSection,
	LinkedImage
} from 'models';
import LanguageService from 'services/language.service';

// configure type resolvers
const typeResolvers = [
	new TypeResolver(KENTICO_TYPES.SEO,
		() => new SEO()),
	new TypeResolver(KENTICO_TYPES.VIEW,
		() => new View()),
	new TypeResolver(KENTICO_TYPES.CORE,
		() => new Core()),
	new TypeResolver(KENTICO_TYPES.OUTER_LINK,
		() => new OuterLink()),
	new TypeResolver(KENTICO_TYPES.INNER_LINK,
		() => new InnerLink()),
	new TypeResolver(KENTICO_TYPES.DOWNLOAD_LINK,
		() => new DownloadLink()),
	new TypeResolver(KENTICO_TYPES.PROMO_SECTION,
		() => new PromoSection()),
	new TypeResolver(KENTICO_TYPES.CONTACT_FORM_SECTION,
		() => new ContactFormSection()),
	new TypeResolver(KENTICO_TYPES.PAGE_NOT_FOUND,
		() => new PageNotFound()),
	new TypeResolver(KENTICO_TYPES.COLUMN,
		() => new Column()),
	new TypeResolver(KENTICO_TYPES.MULTIPLE_COLUMNS_WITH_HEADER_SECTION,
		() => new MultipleColumnsWithHeaderSection()),
	new TypeResolver(KENTICO_TYPES.MULTIPLE_COLUMNS_SECTION,
		() => new MultipleColumnsSection()),
	new TypeResolver(KENTICO_TYPES.TWO_COLUMNS_WITH_HEADER_SECTION,
		() => new TwoColumnsWithHeaderSection()),
	new TypeResolver(KENTICO_TYPES.THREE_COLUMNS_WITH_HEADER_SECTION,
		() => new ThreeColumnsWithHeaderSection()),
	new TypeResolver(KENTICO_TYPES.FOUR_COLUMNS_WITH_HEADER_SECTION,
		() => new FourColumnsWithHeaderSection()),
	new TypeResolver(KENTICO_TYPES.SLIDER_SECTION_WITH_HEADER,
		() => new SliderSectionWithHeader()),
	new TypeResolver(KENTICO_TYPES.SLIDER_SECTION,
		() => new SliderSection()),
	new TypeResolver(KENTICO_TYPES.SLIDE,
		() => new Slide()),
	new TypeResolver(KENTICO_TYPES.HERO_SECTION,
		() => new HeroSection()),
	new TypeResolver(KENTICO_TYPES.COLLABORATIONS_COLUMNS,
		() => new CollaborationSection()),
	new TypeResolver(KENTICO_TYPES.TESTIMONIALS,
		() => new TestimonialsSection()),
	new TypeResolver(KENTICO_TYPES.HERO_HEADING_TEXT,
		() => new HeroHeadingText()),
	new TypeResolver(KENTICO_TYPES.NAVIGATOR,
		() => new Navigator()),
	new TypeResolver(KENTICO_TYPES.NAVBAR,
		() => new Navbar()),
	new TypeResolver(KENTICO_TYPES.FOOTER_SECTION,
		() => new FooterSection()),
	new TypeResolver(KENTICO_TYPES.VOICE_RECORDER,
		() => new VoiceRecorder()),
	new TypeResolver(KENTICO_TYPES.PHONE_LINK,
		() => new PhoneLink()),
	new TypeResolver(KENTICO_TYPES.BLOG_ARTICLE,
		() => new BlogArticle()),
	new TypeResolver(KENTICO_TYPES.CASE_STUDY_ARTICLE,
		() => new CaseStudyArticle()),
	new TypeResolver(KENTICO_TYPES.PARTNERS_SECTION,
		() => new PartnersSection()),
	new TypeResolver(KENTICO_TYPES.BLOG_LISTING,
		() => new BlogListing()),
	new TypeResolver(KENTICO_TYPES.CASE_STUDIES_LISTING,
		() => new CaseStudiesListing()),
	new TypeResolver(KENTICO_TYPES.TRANSLATIONS,
		() => new Translations()),
	new TypeResolver(KENTICO_TYPES.GLOBAL_TRANSLATIONS,
		() => new GlobalTranslation()),
	new TypeResolver(KENTICO_TYPES.BLOG_SECTION_TRANSLATION,
		() => new BlogSectionTranslation()),
	new TypeResolver(KENTICO_TYPES.CASE_STUDIES_SECTION_TRANSLATION,
		() => new CaseStudiesSectionTranslation()),
	new TypeResolver(KENTICO_TYPES.CASE_STUDIES_LAW_TRANSLATION,
		() => new CaseStudiesLawTranslation()),
	new TypeResolver(KENTICO_TYPES.BLOCK_QUOTE,
		() => new BlockQuote()),
	new TypeResolver(KENTICO_TYPES.BLOG_AUTHOR,
		() => new BlogAuthor()),
	new TypeResolver(KENTICO_TYPES.NEWSLETTER,
		() => new Newsletter()),
	new TypeResolver(KENTICO_TYPES.RICH_TEXT_WITH_TITLE,
		() => new RichTextWithTitle()),
	new TypeResolver(KENTICO_TYPES.EMPLOYEE_CONTACT_SECTION,
		() => new EmployeeContactSection()),
	new TypeResolver(KENTICO_TYPES.CTA_CONTACT_SECTION,
		() => new CtaContactSection()),
	new TypeResolver(KENTICO_TYPES.SLIDE_WITH_ICON, () => new SlideWithIcon()),
	new TypeResolver(KENTICO_TYPES.SLIDER_WITH_ICON, () => new SliderWithIcon()),
	new TypeResolver(KENTICO_TYPES.SLIDER_BUTTON, () => new SliderButton()),
	new TypeResolver(KENTICO_TYPES.SLIDER_BUTTONS_SECTION, () => new SliderButtonsSection()),
	new TypeResolver(KENTICO_TYPES.LINKED_IMAGE,
		() => new LinkedImage()),
];

const isPreview = PREVIEW_API_KEY !== '';

if (isPreview) {
	console.info('PREVIEW MODE IS ENABLED');
}

export default new DeliveryClient({
    baseUrl: KENTICO_PROXY_BASE_URL,
	projectId: PROJECT_ID,
	typeResolvers: [...typeResolvers],
	enablePreviewMode: isPreview,
	previewApiKey: PREVIEW_API_KEY,
	defaultLanguage: LanguageService.getSelectedLanguage(),
	// @ts-ignore
	modularContentResolver: {
		modularContentWrapperTag: 'div',
	},
});
