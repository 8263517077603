import React, {  FunctionComponent } from 'react';
import OuterLink from 'models/OuterLink';
import Icon from 'components/Icon/Icon';
import faYoutube from '@fortawesome/fontawesome-free-brands/faYoutube';
import LinkParser from 'components/LinkParser/LinkParser';

export interface YoutubeLinkProps {
	data: OuterLink;
	className?: string;
}

const YoutubeLink: FunctionComponent<YoutubeLinkProps> = ({ data, className }) => {
	return (
		<LinkParser
			className={className}
			data={data}
		>
			<Icon icon={faYoutube} />
		</LinkParser>
	);
};

YoutubeLink.defaultProps = {
	className: '',
};

export default YoutubeLink;
