import get from 'lodash/get';
import ContentItemWithResolver from "models/ContentItemWithResolver";

export default class OuterLink extends ContentItemWithResolver {
	get text() {
		return get(this, '_text.value', '');
	}

	get link() {
		return get(this, '_link.value', '');
	}

	get newWindow() {
		return get(this, '_new_window.value[0].name', 'true') === 'true';
	}

	get file() {
		return get(this, '_file.value[0].url', '');
	}

	get fileType() {
		return get(this, '_file.value[0].type', '');
	}

	get fileName() {
		return get(this, '_file.value[0].name', '');
	}
}
