import React, { FunctionComponent } from 'react';
import styles from './HorizontalLine.module.scss';

export interface HorizontalLineProps {
  isShown: boolean;
  isPaused?: boolean;
  animationTime?: any;
}

const HorizontalLine: FunctionComponent<HorizontalLineProps> = ({
  isShown,
  isPaused,
  animationTime = '8s',
}) => (
  <div className={`${styles.Line}
    ${isShown ? styles.Show : ''}
    ${isPaused ? styles.Paused : ''}`}
  >
    <div
      className={`${styles.In}
        ${isPaused ? styles.Out : ''}`}
      style={{ animationDuration: isShown && animationTime }}
    />
  </div>
);

export default HorizontalLine;
