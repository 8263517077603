import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { DropDownValue } from "models/DropDownValue";
import WindowStore from "stores/window.store";
import { computed, observable, action, reaction } from "mobx";
import DownButton from "components/Buttons/Arrow/DownButton";
import HorizontalLine from "components/HorizontalLine/HorizontalLine";
import { Bind } from "lodash-decorators";
import root from "window-or-global";
import styles from "./DropDown.module.scss";

export interface DropDownProps {
  windowStore: WindowStore;
  onSelect?: (value: string) => any;
  valueList: DropDownValue[];
  value?: string;
  className?: string;
  defualtValue?: string;
  isError?: boolean;
  mobileLayoutChange?: boolean;
}

@inject("windowStore")
@observer
class DropDown extends Component<DropDownProps> {
  static defaultProps = {
    className: "",
    onSelect: (value: string) => null,
    value: null,
  };

  @observable value;

  @observable values: DropDownValue[] = [];

  @observable open = false;

  listRef: any;

  disposeToggleReaction;

  @computed get selectedValuePresentation() {
    const selectedValue = this.values.find(
      (data: DropDownValue) => data.value === this.value
    );
    return selectedValue ? selectedValue.representation : "";
  }

  componentDidMount() {
    this.setData();
    this.onListToggle();
    this.disposeToggleReaction = reaction(() => this.open, this.onListToggle);
    root.addEventListener("resize", this.onListToggle);
  }

  componentDidUpdate() {
    this.setData();
  }

  componentWillUnmount() {
    this.disposeToggleReaction();
    root.removeEventListener("resize", this.onListToggle);
  }

  @Bind
  onListToggle() {
    if (this.listRef) {
      if (!this.open) {
        const listHeight = this.listRef.getBoundingClientRect().height;
        this.listRef.style.marginTop = `${-listHeight}px`;
      } else {
        this.listRef.style.transitionDuration = "0.6s";
        this.listRef.style.marginTop = 0;
      }
    }
  }

  @action.bound
  setData() {
    const { valueList, value, defualtValue } = this.props;
    this.values = valueList;
    this.value = value || (defualtValue ? defualtValue : this.values[0].value);
  }

  @action.bound
  onSelect(value: string) {
    const { onSelect } = this.props;
    this.value = value;
    if (onSelect) {
      onSelect(value);
    }
    this.closeDropDown();
  }

  @action.bound
  openDropDown() {
    this.open = true;
  }

  @action.bound
  closeDropDown() {
    this.open = false;
  }

  @action.bound
  toggleDropDown() {
    this.open ? this.closeDropDown() : this.openDropDown();
  }

  render() {
    const {
      windowStore,
      valueList,
      value,
      className,
      defualtValue,
      isError,
      mobileLayoutChange,
    } = this.props;

    const notSelectedValues = valueList.filter(
      (data) => data.value !== this.value
    );

    return (
      <div
        className={`${className} ${styles.Container} ${isError &&
          styles.error}`}
      >
        {windowStore.isMobile && mobileLayoutChange && (
          <select
            defaultValue={defualtValue}
            className={styles.NativeSelect}
            value={value || this.value}
            onChange={(event: any) => this.onSelect(event.target.value)}
          >
            {notSelectedValues.map((data: DropDownValue) => (
              <option key={data.value} value={data.value}>
                {data.representation}
              </option>
            ))}
          </select>
        )}
        <div
          className={`${styles.SelectedValueContainer} ${
            windowStore.isMobile && mobileLayoutChange
              ? styles.NotClickable
              : ""
          }`}
          onClick={this.toggleDropDown}
        >
          <HorizontalLine className={styles.TopLine} />
          <span className={styles.SelectedValue}>
            {this.selectedValuePresentation
              ? this.selectedValuePresentation
              : defualtValue}
          </span>
          <DownButton
            className={`${styles.ArrowButton} ${
              this.open ? styles.ArrowActive : ""
            }`}
          />
          <HorizontalLine
            className={`${styles.BottomLine} ${this.open ? "" : styles.Hidden}`}
          />
        </div>
        {(!windowStore.isMobile || !mobileLayoutChange) && (
          <ul className={styles.List} ref={(ref) => (this.listRef = ref)}>
            {notSelectedValues.map((data: DropDownValue) => (
              <li
                key={data.value}
                className={styles.Value}
                onClick={() => this.onSelect(data.value)}
              >
                {data.representation}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default DropDown as any;
