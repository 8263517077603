import React, { FunctionComponent } from 'react';
import RichTextParser from 'components/RichTextParser/RichTextParser';
import styles from './CollaborationCard.module.scss';

export interface CollaborationCard {
  title: string,
  backgroundImage: string,
  text: string,
  image: string,
}

const CollaborationCard: FunctionComponent<any> = ({ title, backgroundImage, text, image }) => (
  <div className={styles.wrapper}>
  <div className={styles.container} style={{ backgroundImage: `url(${backgroundImage})`}}>
    <div className={styles.wrapperImage}>
      <img src={image} alt={title} className={styles.image} />
    </div>
    <RichTextParser content={title} className={styles.title} />
    <RichTextParser content={text} />
  </div>
  </div>
);

CollaborationCard.defaultProps = {
	className: '',
};

export default CollaborationCard;
