import get from 'lodash/get';
import ContentItemWithResolver from "models/ContentItemWithResolver";

export default class Navbar extends ContentItemWithResolver {
	get contactButtonLink() {
		return get(this, '_contact_button_link[0]', {});
	}

	get links() {
		return get(this, '_links', []);
	}

	get linkedInLink() {
		return get(this, '_linkedin_link[0]', '');
	}

	get youTubeLink() {
		return get(this, '_youtube_link[0]', '');
	}

	get facebookLink() {
		return get(this, '_facebook_link[0]', '');
	}
}
