import { KENTICO_TYPES } from 'common/consts/kentico';
import { SortOrder } from 'kentico-cloud-delivery';
import Client from './kenticoClient';

class BlogService {
	static async fetchArticle(slug: string) {
		const items = await Client.items()
			.type(KENTICO_TYPES.BLOG_ARTICLE)
			.depthParameter(10)
			.equalsFilter('elements.slug', slug)
			.getPromise();

		if (items.isEmpty) {
			return null;
		}

		return items.lastItem;
	}

	static async fetchArticleList() {
		const request = Client.items()
			.type(KENTICO_TYPES.BLOG_ARTICLE)
			.depthParameter(0)
			.elementsParameter([
				'categories__blog_sections',
				'main_category__blog_sections',
				'image',
				'title',
				'lead',
				'slug',
			])
			.orderParameter('elements.publication_date', SortOrder.desc);

		const response = await request.getPromise();

		if (response.isEmpty) {
			return [];
		}

		return response.items;
	}
}

export default BlogService;
