import React, {  FunctionComponent } from 'react';
import styles from './HorizontalLine.module.scss';

export interface HorizontalLineProps {
	className?: string;
}

const HorizontalLine: FunctionComponent<HorizontalLineProps> = ({ className }) => (
	<hr
		className={`${className} ${styles.HorizontalLine}`}
	/>
);
HorizontalLine.defaultProps = {
	className: '',
};

export default HorizontalLine;
